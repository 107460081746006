header{
    .header_top{
        padding: 10px 0;
        background-color: #000D3B;
    }
    .header-flex-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;        
        .header-left {
            display: flex;
			align-items: center;
            gap: 25px;
           a{
               color: #fff;
               text-decoration: none;
               font-family: ProductSans-Regular;
               .icon{
                   color: #FFBF3C;
                   margin-right: 5px;
               }
           }
            .left-item{
                &:last-child{
                    margin-right: 0;
                    @include r(768) {
                        display: none;
                    }
                }
                .number_strong{
                    font-weight: bold;
                }
            }
           
		}
        .header-right{
           ul{
               display: flex;
               padding: 0;
               margin: 0;
               list-style: none;
               li{
                   &:last-child{
                       margin-left: 30px;
                   }
               }
            a{
                   color: #fff;
                   font-family: ProductSans-Regular;
                   text-decoration: none;
               }
           }
           .lang{
            position: relative;
            min-width: 120px;          
            .lang-img{
                color: #FFBF3C;
            }
            display: flex;
            align-items: center;
            .nice-select{
                position: relative;
                width: 100%;
                height: 100%;
                background-color: transparent;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-family: ProductSans-Regular;
                padding: 0 ;
                border:none;
                .current{
                    color: #fff;
                    padding-left: 25px;
                }
                &.open{
                    &::after{
                        border-color: #FC3F1D;
                    }
                }
                &:focus{
                    border-color: #E8E8E8 !important;

                }
                .list{
                    width: 100%;
                    padding: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    li{
                        margin: 0;
                    }
                    .option {
                        background-color: #fff;
                        color: #000000;
                        margin-bottom: 5px;
                        &.selected {
                            color: #BDBDBD;
                            font-weight: normal;
                        }
                    }
                }
               
            }
            .lang-img{
                position: absolute;
                z-index: 999;
            }
        }
        }
    }
    .catalog-drop{
        width: 100%;
        display: none;
        position: absolute;
        top: 160px;
        height: 670px;
        overflow: hidden;
        z-index: 1054;
        background: #FFFFFF;
        .right-menu{
            margin-top: 42px;
        }
        .left-menu{
            .tab{
                a{
                    color: #A9ADB8;font-size: 18px;
                    line-height: 22px;
                    font-family: 'ProductSans'; 
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                 
                    span{
                        line-height: 100%;
                        font-family: 'ProductSans';
                    }
                    img{
                        filter:grayscale(100%) sepia(2%) brightness(185%) saturate(0%) contrast(130%); 
                        width: 24px;
                        height: 24px;
                        object-fit: contain;
                        margin-right: 16px;
                    }
                }
                .tablinks{
                    &.active{
                        color: #000000;
                        img{
                            filter: none;
                        }
                    }
                    &:hover{
                        color: #000000;
                    }
                }
            }
        }
        .left-two-menu{
            .left-title{
                .tabcontent{
                    
                    display: block;
                    
                    h4{
                        margin-left: 28px; 
                        color: #1861C6;font-size: 35px;
                        line-height: 42px;
                        font-family: 'ProductSans';
                    }
                    h6{
                        color: #000000;font-size: 16px;
                        line-height: 26px;
                        font-family: 'ProductSans-Medium';  
                        margin-top: 16px ;
                        margin-bottom: 0;
                    }
                    // a{
                    //     text-decoration: none;color: #1861C6;font-size: 16px;
                    //     line-height: 19px;
                    //     font-family: 'ProductSans';
                    // }
                    a{
                        text-decoration: none;
                        display: block;
                        color: #000000;font-size: 16px;
                        line-height: 26px;
                        font-family: 'ProductSans';
                        &:hover{
                            color: #FC3F1D;
                        }
                    }
                    .colapse-link{
                        color:#1861c6;
                        &:hover{
                            color:#1861c6; 
                        }
                    }
                    .subcontent{
                        list-style: none;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        .tabcontent-item{
                         width: 33.3%;   
                        }
                    }
                }
            }
        }
    }
    .header-menu{
        @include r(992) {
            display: none;
        }
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
        padding-top: 30px;
        .menu-top{
            display: flex;
            align-items: center;
        }
        .brand{
            display: flex;
            align-items: center;
            width: 16%;
            a{
                text-decoration: none;
                .logo{
                    margin-right: 24px;
                   h4{
                    margin: 0;
                    color: #1B6CDD;
                    font-size: 21px;
                    line-height: 25px;    
                    font-family: 'ProductSans-Medium';
                    span{
                        color: #B3B3B3; font-size: 21px;
                        line-height: 25px;    
                        font-family: 'ProductSans-Medium';
                    }
                   }
                   p{
                    color: #B3B3B3;font-size: 12px;
                    line-height: 15px;font-family: 'ProductSans';width: 124px;
                   }
                }
                .logo-text{
                    p{
                        margin: 0;
                        line-height: 1.2;
                        color: #8A8A8A;
                        font-family: ProductSans-Regular;
                    }
                }
            }
        }
        .catalog-button-bloc{
            .catalog-buttons{
                
                .catalog-button{
                    
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    padding: 16px;
                    margin-bottom: 0;
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 22px;
                    color: #1861C6;
                    background-color: #fff;
                }
            }
        }
        .elements{
            width: 57%;
            padding: 0 20px;
            padding-left: 45px;
        }
        
        .menu-top-right{
            width: 20%;
            display: flex;
            padding-left: 20px;
            justify-content: space-between;
            @include r(1024){
                padding-left: 0;
            }
            .page{
                .link{
                    text-decoration: none;
                    font-family: ProductSans-Regular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    color: #13161F;
                    .page-card{
                        .icon{
                            display: flex;
                            justify-content: center;
                        }
                    }

                }
            }
            .login{
                .auth{
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .dropdown-menu{
                    margin-top: 20px;
                    border: 0;
                    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
                    width: 345px;
                    border-radius: 0;
                    .profile-card {
                        background: #fff;
                        border-radius: 0;
                        padding: 16px;
                        @include r(768){
                            width: 80%;
                            margin-left: 65px;
                        }
                        @include r(576){
                            width: 90%;
                            margin-left: 35px;
                        }
                        @include r(479){
                            width: 100% !important;
                            margin-left: 0px !important;
                        }
                        .card-list {
                            padding: 0;
                            margin: 0;
                            list-style-type: none;
                            border-top: 1px solid #D7DDEB;
                            padding-top: 16px;
                            .card-link {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 52px;
                                border-radius: 8px;
                                text-decoration: none;
                                padding: 0 16px;
                                margin-bottom: 4px;
            
                                &:hover {
                                    background: #F5F6FA;
                                }
            
                                &.active {
                                    background: #F5F6FA;
                                }
            
                                .icon {
                                    width: 24px;
                                    height: 24px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 16px;
            
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
            
                                span {
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 18px;
                                    line-height: 22px;
                                    color: #000000;
                                }
                            }
                        }
                    }

                    .user {
                        margin-bottom: 24px;
                        display: flex;
                        align-items: center;
                        gap: 16px;
        
                        @include r(1024) {
                            margin-bottom: 25px;
        
                            @include r(576) {
                                margin-bottom: 16px;
        
                            }
        
                        }
        
                        .user-photo {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            overflow: hidden;
                            border: 1px solid #FFBF3C;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            box-sizing: border-box;
        
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
        
                            }
                        }
        
                        .user-info {
                            .user-name {
                                font-family: ProductSans;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 18px;
                                line-height: 22px;
                                color: #000000;
                                margin-bottom: 0;

                                @include r(1024) {
                                    font-size: 25px;
                                    line-height: 30px;
        
                                    @include r(576) {
                                        font-size: 20px;
                                        line-height: 24px;
                                        margin-bottom: 0;
        
                                    }
        
                                }
        
                            }
        
                            .phone {
                                font-family: ProductSans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                color: #7A7D85;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        
    }
}
.menu-main{
  .dropdown-toggle::after{
    content: none;
  }
    .navbar-nav {
        padding: 30px 0 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .nav-link{
        position: relative;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        padding: 0 !important;
        /* identical to box height */
        transition: 0.2s ease all;
        font-family: 'ProductSans-Medium';
        margin-bottom: 10px;
        color: #000000;
        &::after{
            position: absolute;
            content: '';
            display: table;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -10px;
            background-color: transparent;
        }
       
        &:hover{
            color: #1861C6;
            &::after{
                background-color: #FFBF3C;
            }
        }
        &.active{
            &::after{
                background-color: #FFBF3C;
            }
        }

    }

    .menu-container{
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 20px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
        .menu-cover{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .menu-block{
            .menu-title{
                font-family: ProductSans-Regular;
                font-style: normal;
                font-weight: bold;
                font-size: 17px;
                line-height: 22px;
                margin-bottom: 12px;
                a{
                    font-family: ProductSans-Regular;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 17px;
                    line-height: 22px;
                    text-decoration: none;
                    color: #1861C6;
                    display: flex;
                    align-items: center;
                    span{
                        margin-right: 10px;
                    }
                }
            }

            .menu-list{
                margin: 0;
                padding: 0;
                list-style-type: none;
                .menu-item{
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 4px;
                    .menu-link{
                        font-family: ProductSans-Regular;
                        display: inline-block;
                        font-style: normal;
                        font-weight: normal;                        
                        color: #000000;
                        text-decoration: none;
                        transition: 0.3s ease all;
                        &:hover{
                            color: #FC3F1D;
                        }
                    }
                }
            }
        }
    }
}
.search{
    .form-control{
        background: #F6F6F6;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        height: 56px;
        line-height: 20px;
        box-shadow: none;
        padding-left: 45px;
        border-radius: 8px;
        border-radius: 8px 0px 0px 8px !important;
        font-family: ProductSans-Regular;
        &:focus{
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-sizing: border-box;
            border-radius: 8px;
        }

    }
    .icon{
        height: auto;
        position: absolute;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
    }
    .input-group{
        position: relative;
    }
    .search-button{
        color: #fff;
    border-radius: 0px 8px 8px 0px;
    border-color: transparent;
    box-shadow: none;
    transition: 0.3s ease all;
    height: 100%;
    padding: 0 32px;
    background: #13369E;

    &:focus{
        background: #13369E;
        outline: none;
        box-shadow: none;
        border-color: transparent;

      
    }
    &.active{
        background: #13369E;
        img{
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    }
}

body.mobile-left > .wrapper{
    margin: 0 !important;
}
.mobil-menu{
    display: none;
    @include r(992) {
       display: block;
    }
    .header_body{
        background-color: $white;
        position: relative;
        box-shadow: 0 2px 10px -8px rgba($color: #000000, $alpha: 1.0);
        padding: 5px 0;
        .header_nav{
            a{
                text-decoration: none;
                font-size: 14px;
            }
           
            .site_nav{
                @include r(576){
                    padding-top: 0;

                }
                nav{
                    border: none;
                    justify-self: center;
                    .navbar-toggle{
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 0 !important;
                        padding: 0;
                        width: auto;

                        .hidden_icon{
                            display: none;
                        }
                    }
                    .min_heder_top{
                        display: flex;
                        justify-content: space-between;
                        padding: 15px 20px;
                        align-items: center;
                        .lang{
                            position: relative;
                            min-width: 150px;
                            height: 48px;
                           .lang-img{
                               margin-left: 15px;
                           }
                            display: flex;
                            align-items: center;
                            .nice-select{
                                position: relative;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-family: ProductSans-Regular;
                                padding: 0 ;
                                &.open{
                                    &::after{
                                        border-color: #ED138D;
                                    }
                                }
                                &:focus{
                                    border-color: #E8E8E8 !important;
                
                                }
                                .list{
                                    width: 100%;
                                    padding: 14px;
                                    .option {
                                        background-color: #fff;
                                        margin-bottom: 5px;
                                        &.selected {
                                            color: #BDBDBD;
                                            font-weight: normal;
                                        }
                                    }
                                }
                               
                            }
                            .lang-img{
                                position: absolute;
                                z-index: 999;
                            }
                        }
                        .nav_close{
                            margin-top: 0;
                            line-height: 20px;
                            font-size: 20px;
                        }
                    }
                   
                    .site_menu_ul{
                        @include r(1024) {
                            display: block !important;
                            width: 100%;
                            text-align: center;
                            padding-top: 0;
                        }

                    }
                    ul{
                        display: flex;
                        justify-content:center;
                        padding: 0;
                        margin: 0;
                        padding-top: 0;
                        list-style-type: none;
                        
                        a{
                            text-transform: none;
                        }
                        li{
                            text-align: left;
                        }
                        
                        .btn-gold{
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            padding: 7px 25px ;
                            /* identical to box height */
                            transition: 0.2s ease all;
                            font-family: 'ProductSans-Medium';
                            text-transform: none;
                            color: #000000;
                            &:hover{
                                color: $dark !important;
                            }                           
                            &:active{
                                color: #F12482;
                            }

                        }
                        .search{
                            a{
                                padding: 0;
                                margin-top: 3px;
                            }
                        }
                            
                        .active{
                            color: #F12482;
                            
                        }
                        &:active{
                            color: #F12482;
                        }
                       
                        
                    }
                    .collapse {
                        z-index: 1024;
                     ul{
                        @include r(1200) {
                            display: inline-flex;
                            position: absolute;
                            text-align: center;
                            

                        }
                     }
                     @include r(1024){
                         display: block;
                         
                     }   
                    }
                    .show{
                        @include r(1024){
                            .btn-gold{
                            &:hover{
                                background: none !important;
                            }
                            }
                        }  
                        @include r(479){
                            width: 100% !important;                           
                        }
                    }
                   
                    .search-box{
                        padding: 0;
                    }
                }
               
                
            }
    
        }
    }
    .search-box{.close-search{
       .fa-times{
           color: #7F8081;
       }
    }}
    .sina-brand{
        
           
        
        @include r(1024) {
            margin: 0 !important;
        }
        .min_logo{
            display: none;
        }

        position: relative !important;
        .large_logo{
            height: auto !important;
            max-height: 100%;
            @include r(1200) {
                margin-top: 10px;
            }
            @include r(1024) {
                margin: 0 !important;

            }
            
        }
        @include r(479) {
            // position: absolute !important;
            padding-top: 6px;
            max-width: none;
            .large_logo{
                display: none;
            }
            .min_logo{
                display: inline-block;
                width: 120px;
                margin-top: 0px;
                padding: 0;
                height: auto;
            }
        }
        
        
    }
    .mobile-sidebar{
        &::after{
            display: table;
            content: "";
            clear: both;
        }
    }

    .sina-nav-header{
        @include r(1024) {    
            display: flex;
            justify-content: space-between;
            align-items: center;
            .extension-nav{
                float: none !important;
                display: block;
                width: 40px;
                position: relative;
                margin: 0;
                right:0 !important;
                .search{
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }
        .sina-brand{
            @media (max-width: 480) {
               display: block;
        }
            h4{
                margin: 0;
                color: #1B6CDD;
                font-size: 21px;
                line-height: 25px;    
                font-family: 'ProductSans-Medium';
                span{
                    color: #B3B3B3; font-size: 21px;
                    line-height: 25px;    
                    font-family: 'ProductSans-Medium';
                }
               }
               p{
                color: #B3B3B3;font-size: 12px;
                line-height: 15px;font-family: 'ProductSans';width: 124px;
                @media (max-width: 576px) {
                    width: 161px;
                }
            }
        }
    }

    .sina-nav .extension-nav > ul{
        float: none !important;
    }
    .sina-nav .extension-nav > ul > li{
        float: none !important;
    }
}
