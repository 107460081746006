.banner-slider{
    margin-top: 60px;
    margin-bottom: 70px;
    @include r(992) {
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .banner-title{
   
        p{
            padding-top: 109px;
            color: #303030; font-size: 29px;
            line-height: 40px;font-family: 'ProductSans';
            @include r(1024) {
                padding-top: 100px;
             font-size: 24px;
             line-height: 26px
             }
             
                         
        }
        h4{
            color: #1861C6;font-size: 47px;
            line-height: 57px;font-family: 'ProductSans-Medium';
            margin-top: 33px;
            @include r(1024) {
                font-size: 39px;
                line-height: 45px;
            }
            @include r(992){
                padding-top: 40px;
                @include r(768){
                    padding-top: 0;
                    display: flex;
                    justify-content: center;
                    @include r(576){
                        font-size: 26px;
                        @include r(387){
                            font-size: 25px;
                        }
                    }

                }
            }
        }
    }
    .slick-prev{
        display: none !important; 
        left: 30px;
        z-index: 11;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 50%;
        transition: 0.3s;
        &:hover{
            background: rgba(252, 63, 29, 1);
        }
        &::before{
            opacity: 1;
            content: url(../img/arrow-left.svg);
        }
    }

    .slick-next{
        display: none !important; 
        right: 30px;
        z-index: 11;
        width: 50px;
        height: 50px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 50%;
        transition: 0.3s;
        &:hover{
            background: rgba(252, 63, 29, 1);
        }
        &::before{
            opacity: 1;
            
            content: url(../img/arrow-right.svg);
        }
    }

    .slick-dots{
        bottom: -30px;
        li{
            width: 20px;
            height: 10px;
            background: #D7DDEB;
            border-radius: 27px;
            transition: 0.3s ease all;
            &.slick-active{
                width: 70px;
                height: 10px;
                background: #1861C6;
                border-radius: 10px;
                transition: 0.3s ease all;

            }
            button{
                &::before{
                    content: '';
                }
            }
        }
    }

    .banner-img{
        margin: 10px 0px;
        img{
            min-height: 320px;
            width: 100%;
            border-radius: 4px;
            @include r(1024){
                min-height: 220px;
            }
        }
    }
    .img-bottom{
        img{
            min-height: 150px;
            width: 100%;
            border-radius: 4px;
        }
    }

}
.breadcrumb{
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 16px;
    .breadcrumb-item{
        font-family: ProductSans-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #A9ADB8;
        a{
        color: #A9ADB8;
        text-decoration: none;
        }

    }
    .breadcrumb-item + .breadcrumb-item::before{
        content: "•";
        font-size: 18px;
        line-height: 17px;
        color: #A9ADB8;
    }
}
.section{
    .shop-product-slider-block{
        .block-title{
            margin-top: 70px;
            a{
                font-family: 'ProductSans';
                padding: 12px 23px;
                background: #EBF4FF;
                border-radius: 5px;
            }
        }
    }
}
.banner-litle{
    margin-bottom: 70px;
    .banner-litle-title{
        background: #1861C6;
        border-radius: 12px;
        @include r(768){
            display: none;
        }
        h4{
            padding: 54px 100px 54px 64px;
            color: #FFFFFF;font-size: 35px;
            line-height: 42px;  font-family: ProductSans-Medium;
            p{
                color: #000000;font-family: ProductSans-Medium;margin-left: 55px;
            }
        }
    }
}
.shop-list{
    margin: 35px 0 35px;
    @include r(1024) {
        margin: 20px 0 20px;
    }
    @include r(576) {
        margin: 10px 0 20px;
    }
    a{
        text-decoration: none;
    }
    .shop-item{
        background: #F5F6FA;
        border-radius: 8px;
        padding: 20px;
        transition: 0.3s ease all;
        margin: 15px 0;
        user-select: none;
        .shop-info{
            display: flex;
            gap: 16px;
            margin-bottom: 20px;
            .shop-about{
                max-width: 70%;
                .shop-title{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    margin-bottom: 2px;
                    line-height: 22px;
                    color: #000000;
                    transition: 0.3s ease all;
                }
                .shop-desc{
                    font-family: ProductSans;
                    font-size: 12px;
                    line-height: 15px;
                    color: #7A7D85;
                    margin-bottom: 3px;
                    transition: 0.3s ease all;
                }
                .shop-lavel{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    transition: 0.3s ease all;
                }
                .icon{
                            
                    color: #000000;
                    font-size: 12px;
                    line-height: 15px;
                    font-family: 'ProductSans';
                    img{
                      width: 12px;
                      height: 12px;
                      &:first-child{
                        margin-left: 7px;  
                      }
                    }  
                
            }
            
            }
        }
        .shop-photo{
            background: #fff;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .shop-galerey{
            display: grid;
            grid-template-columns: 65% 35% ;
            box-sizing: border-box;
            .galerey-item{
                background: #FFFFFF;
                border-radius: 4px;
                margin:5px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 95px;
                position: relative;
                img{
                    max-width: 100%;
                    max-height: 100%;
                }
                .price{
                    position: absolute;
                    bottom: 8px;
                    left: 8px;
                    background: #FFBF3C;
                    border-radius: 4px;
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    padding: 4px 6px;
                    color: #000000;
                    
                    transition: 0.3s ease all;
                }
               
                .light{
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
            }
            .galerey-item:first-child(){
                grid-row: 1/4;
                height: 200px !important    ;
              }
        }
        &:hover{
            background: #1861C6;
            .shop-info{
                .shop-about{
                    .shop-title,.shop-desc, .shop-lavel{
                        color: #fff;
                    }
                    .shop-desc{
                        opacity: 0.8;
                    }
                    .icon{
                        color: #FFF;
                    }
                }
            }
            .shop-galerey{
                .galerey-item{
                    .price{
                        display: none;
                    }
                }

            }
            .shop-lavel{ 
                .lavel-text{
                    color: #fff;
                }
                .icon{
                    color: #FFBF3C;
                }
            }
        }
        .shop-lavel{
            font-family: ProductSans;
            font-size: 14px;
            line-height: 14px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 6px;
            .icon{
                color: #7A7D85;
                font-size: 48px;
                line-height: 58px;
                font-family: 'ProductSans-Regular';
                margin-left: 20px;
            }
            .icon-text{
                color: #13369E;font-size: 12px;
                line-height: 15px;font-family: 'ProductSans';
                padding-bottom: 3px;
            }
            .lavel-text{
                line-height: 14px;
                display: flex;
                align-items: center;color: #13369E;font-size: 12px;
                line-height: 15px;font-family: 'ProductSans-Medium';
            }
        }
        
        
    }
  
}
.main-category{
    @include r(992){
        max-width: 960px;
    }
    @include r(768){
        max-width: 765px !important;
    }
    .catalog-list{
        a{
            text-decoration: none;
        }
        .slick-slide{
            outline: none;
            &:focus{
                outline: none;
            }
        }
        .catalog-item{
            background: #F5F6FA;
            border-radius: 8px;
            padding: 12px 9px;
            transition: .3s ease all;
            // margin: 0 10px;
            height: 170px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: space-between;
            user-select: none;
            &:focus{
                outline: none;
            }
            .catalog-title{
                font-family: ProductSans-Bold;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 17px;
                text-align: center;
                color: #13369E;
                margin-bottom: 2px;
                transition: .3s ease all;

            }
            .product-count{
                font-family: ProductSans;
                font-size: 13px;
                line-height: 15px;
                text-align: center;
                color: #7A7D85;
                margin-bottom: 8px;
                transition: .3s ease all;

            }
            .product-photo{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &:hover{
                background: #FFBF3C;
                .catalog-title{
                    color: #000000;
                }
                .product-count{
                    color: #fff;
                }
            }
        }

        .slick-prev{
            z-index: 11;
            width: 50px;
            height: 50px;
           
            top: 50%;
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            border-radius: 50%;
            &:focus{
            background: #F5F6FA;
    
            }
            &::before{
                opacity: .8 !important;
                content: url(../img/left.svg);
            }
            &:hover{
                background: #1861C6;
                opacity: 1 !important;
    
                &::before{
                    opacity: 1 !important;
                    -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1);
                    
                }
            }
           
        }
    
        .slick-next{
            z-index: 11;
            width: 50px;
            height: 50px;
            top: 50%;
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            border-radius: 8px;
            border-radius: 50%;
            transition: 0.3s;
            &:focus{
                background: #F5F6FA;
        
                }
            &::before{
                opacity: .8 !important;
                content: url(../img/right.svg);
            }
            &:hover{
                background: #1861C6;
                &::before{
                    opacity: 1 !important;
                    -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1);
                    
                }
            }
        }
    
        .slider_wrap * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            }
            .slick-slide { margin: 0 10px;}
            .slider-item img { max-width: 100%;}
    }
}

.little-banners{
    padding: 70px 0;
    @include r(992) {
        padding: 30px 0 14px;
    }
    .banner-img{
        img{
            width: 100%;
            height: 210px;
            border-radius: 4px;
        }
    }
}

.article-section{
    margin-bottom: 10px;
    @include r(992) {
        margin-bottom: 0px;
    }
}
.block-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    font-family: ProductSans-Regular;
    h2{
        font-family: 'ProductSans';
        font-size: 35px;
        color: #000000;
        font-weight: bold;
    }
    a{
        font-family: 'ProductSans';
        color: #1861C6;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        span{
            font-size: 18px;
            margin-right: 10px;
        }
    }
    @include r(992) {
        margin-bottom: 16px;
        h2{
            font-size: 24px;
        }
        a{
            span{
                font-size: 14px;
                margin-right: 10px;
            }
        }
        @include r(420) {
            margin-bottom: 8px;
            h2{
                font-size: 20px;
            }
            a{
                span{
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.for-slider{
    .slick-prev{
        margin-left: 10px;
        z-index: 11;
        width: 50px;
        height: 50px;
        background: #F5F6FA;
        border-radius: 50%;
        transition: 0.3s;
        top: 30%;
        &:focus{
        background: #F5F6FA;

        }
        &::before{
            opacity: .8 !important;
            content: url(../img/left.svg);
        }
        &:hover{
            background: #1861C6;
            opacity: 1 !important;

            &::before{
                opacity: 1 !important;
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
                
            }
        }
       
    }

    .slick-next{
        z-index: 11;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        top: 30%;
        background: #F5F6FA;
        border-radius: 50%;
        transition: 0.3s;
        &:focus{
            background: #F5F6FA;
    
            }
        &::before{
            opacity: .8 !important;
            content: url(../img/right.svg);
        }
        &:hover{
            background: #1861C6;
            &::before{
                opacity: 1 !important;
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
                
            }
        }
    }

    .slider_wrap * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        }
        .slick-slide { margin: 0 10px;}
        .slider-item img { max-width: 100%;}
}


.reklam-banner{
    width: 100%;
    img{
        width: 100%;
    }
}
.partners{

    margin-bottom: 50px;
    .block-title{
        margin-bottom: 40px;

    }
    @include r(992) {
        margin-bottom: 30px;
        .block-title{
            margin-bottom: 16px;
        }
        @include r(420) {
        }
    }
   .partners-content{
    margin-top: 70px;
   }
}
.partners-list{
    .partners-item{
        background: #F5F6FA;
        border-radius: 8px;
        border: 1px solid #F5F6FA;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        height: 98px;
        margin: 15px 0;
        img{
            max-width: 100%;
        }
        @include r(992) {
            margin: 10px 0;
            @include r(420) {
            margin: 5px 0;

            }
        }
    }
}
.slider-block{
    padding: 30px 0 50px;
    @include r(992) {
        padding: 10px 0 30px;
        @include r(420) {
            padding: 10px 0 20px;

        }
    }
}
.favorite-block{
margin-top: 300px;
@include r(1024){
    margin-top: 20px !important;
}
}
.mycard{
    padding: 30px 0;
    .order-card{
        background: #F5F6FA;
        border-radius: 8px;
        padding: 30px;
        .info-card{
            .info-list{
                padding: 0;
                margin: 0;
                margin-bottom: 10px;
                list-style-type: none;
                .info-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-family: ProductSans-Regular;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 16px;
                        color: #000000;
                    }               
                    .strong{
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 22px;
                        text-align: right;
                        color: #000000;
                    }
                }
            }
            .total_price{
                .total-title{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                }
                .price{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 36px;
                    color: #000000;
                    margin-bottom: 30px;
                }
            }
            .order-button{
                width: 100%;
                height: 56px;
                background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
                box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
                border-radius: 8px;
                span{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
    
                }
            }
        }
    }
    .order-list{
        .order-item{
            box-sizing: border-box;
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 20px;
            display: flex;
            align-items: center;
            height: 200px;
            justify-content: space-between;
            margin-bottom: 30px;
            position: relative;
            @include r(992) {
                flex-wrap: wrap;
                height: auto !important;
            }
            &:last-child(){
                margin-bottom: 0;
            }
           
                .order-photo{
                    width: 180px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                   
                    img{
                        max-width: 100%;
                    }    
                }
                .order-info{
                    .product-title{
                        font-family: ProductSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;
                    }
                    .sale{
                        font-family: ProductSans;
                        font-size: 14px;
                        line-height: 17px;
                        color: #7A7D85;
                        margin-bottom: 4px;
                    }
                    .shop{
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 0;
                        color: #1861C6;
                        text-decoration: none;
                    }
                }
                .product-info-main{
                    max-width: 70%;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    @include r(992) {
                        padding:0;
                        @include r(768) {
                            max-width: 100%;
                            flex-wrap: wrap;
                            gap: 16px;
                            margin-top: 16px;
                        }
                    }
                }
                .order-product-price{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding-left: 50px;
                    @include r(992) {
                        padding-left: 20px;
                        @include r(768) {
                            width: 100%;
                            padding-left: 0px;

                        }
                    }
                    .price{
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 29px;
                        white-space: nowrap;
                        color: #1861C6;
                        margin-bottom: 0;

                    }
                    .for-count-input{
                        width: 94px;
                        height: 56px;
                        border: 1px solid #A9ADB8;
                        box-sizing: border-box;
                        border-radius: 8px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 16px;
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                        }

                        /* Firefox */
                        input[type=number] {
                        -moz-appearance: textfield;
                        }
                        .counter-button{
                            padding: 0;
                            border: none;
                            background-color: #fff;
                            font-weight: 300;
                            .icon{
                                font-weight: 100;
                                font-size: 12px;
                            }
                        }
                        .count-input{
                            border: none;
                            text-align: center;
                            padding: 0;
                            background-color: #fff;

                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }

                        }
                        
                    }
                   
                }
                .action{
                    display: flex;
                    gap: 15px;
                    @include r(992) {
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                    button{
                        padding: 0;
                        border: none;
                        background-color: #fff;
                        font-size: 20px;
                        line-height: 20px;
                        color: #A9ADB8;
                    }
                }
            
            
        }
    }
    .empty-texts{
        .text{
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            .danger-text{
               color: #1861C6; 
            }
        }
        
    }
    .empty-img{
        margin-top: 40px;
        img{
            max-width: 100%;
        }
    }
}
.pages{
    padding: 30px 0;
    @include r(1024) {
        padding: 20px 0;
        
    }
}
.favorite{
    padding: 20px 0;
    .product-item{
        border: 1px solid #D7DDEB;
        
        @include r(576) {
            padding: 10px;            
        }
        @include r(479){
            width: 80%;
    margin-left: 50px;
        }
        @include r(420){
            width: 90%;
            margin-left: 20px;
        }
        .product-photo{
            padding: 0;
            .icon-heart{
                top: 0;
                right: 0;
            }
          
        }
        .product-info-content{
            margin-bottom: 28px;
        }
        .add_to_card_button{
            background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
            box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
            border-radius: 8px;
            height: 48px;
            width: 100%;
            span{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #000000;
            }
        }
       
    }
    
    .product_card_content{ 
        a{
            text-decoration:none;
        }
        .product-item {
            background: #f5f6f6;
            @include r(1024){
                margin-bottom: 20px;
            }

            .product-photo{
                &:hover{
                    margin: 0;
                    transition:2s;
                    transition-timing-function: ease-out;
                    .icon-heart{
                        display: block;
                    }
                }
                margin:  20px;
                .icon-heart{
                    display: none;
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    font-size: 22px;
                    line-height: 20px;
                    color: #BDBDBD;
                    
                    .heart-active{
                        display: none;
                    }
                    .heart-default{
                        display: none;
                    }
                    &.active{
                        .heart-default{
                            display: none;
                        }
                        .heart-active{
                            display: block;
                            color: #FFBF3C;
                        }
                    }
                }
                
            }
            .product-info{
                
                    padding: 20px;
                @include r(576){
                    padding: 6px;
                }
                .product-link{
                    .product-info-content{
                        padding: 0;
                        .content-img{
                            img{
                                margin-right: 14px;
                            }
                            .content-text{
                                p{
                                    color: #3D3D3D; font-size: 16px;
                                    line-height: 19px; font-family: 'ProductSans-Medium';
                                }
                                .icon{
                                    img{
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        .content-donation{
                            margin-top: 20px;
                            span{
                                color: #8F8386;font-size: 16px;
                                line-height: 19px;font-family: 'ProductSans';
                            }
                            p{
                                color: #414141;font-size: 24.6154px;
                                line-height: 30px;
                                font-family: 'ProductSans';
                            }
                        }
                        .content-check{
                            margin-top: 20px;
                            img{
                                margin-right: 8px;
                            }
                            p{
                                color: #13369E;  font-size: 14px;
                                line-height: 17px; font-family: ProductSans;
                                span{
                                    color:#8F8386;
                                }
                            }
                        }
                        .content-contact{
                            margin-top: 20px;
                            .contact-text{
                                padding: 12px 20px;
                                background: #EFF5FF;
                                border: 1px solid #1B6CDD;
                                border-radius: 5px;
                                display: flex;
                                text-decoration:none;
                                color: #454545;font-size: 18px;
                                line-height: 22px;
                                font-family: 'ProductSans';
                                @include r(1024){
                                    padding: 8px 8px;
                                }
                                @include r(576){
                                    padding: 10px 6px;
                                    font-size: 14px;
                                }
                                img{
                                    margin-right: 16px;
                                }
                                p{
                                    margin-left: 10px;
                                    color: #454545;font-size: 16px;
                                    line-height: 19px; 
                                    font-family: 'ProductSans';
                                    text-decoration-line: underline;
                                }
                            }
                        }
                        .info-footer{
                            padding-bottom: 20px;
                            
                            border-bottom: 1px solid #CDC1C4;
                        }
                        .content-info{
                            margin-top: 20px;
                            img{
                                margin-right: 9px;
                                width: 14px;
                                height: 14px;
                            }
                            p{
                                
                                color: rgba(0, 0, 0, 0.5);font-size: 14px;
                                font-family: 'ProductSans';
                                line-height: 17px;
                            }
                        }
                        .content-footer{
                            padding-top: 16px;
                            p{
                                font-family: 'ProductSans';
                                color: rgba(0, 0, 0, 0.5);
                                text-align: center;
                                font-size: 13px;
                                line-height: 130%;
                                
                            }
                        }
                    }
                }
            }
        }
      
    }
  
}

.auth-modal{
    padding: 0;
    min-width: 100%;
   

    .modal-dialog{
       width: 100%;
        max-width: none;
        min-height: 100vh;
        margin: 0;
        overflow: hidden;
        padding: 120px 225px;
        background: #13369E;
        display: flex;
        align-items: center;
        @include r(1200) {
            padding: 120px 205px;
                @include r(992) {
                    padding: 70px 100px;
                    @include r(768) {
                        padding: 30px;
                     }  
                 }                 
             
      
       }
        .modal-content{
            border-radius: 12px;
            background-color: transparent;
            border: none;
            min-height: 600px;
            min-width: 320px;
            @include r(992) {
                min-height: 550px;
             }  
           .modal-body{
               padding: 0;
               display: flex;
               .auth_img,.auth_form{
                width: 50%;
                @include r(1200) {
                    width: 100%;
                        @include r(992) {
                            min-height: 400px;
                         }                 
                     }
              
               }
               .auth_img{
                user-select: none;
                @include r(1200) {
                   display: none;
                        @include r(992) {
                         }                 
                     }
                   img{
                       width: 100%;
                       height: 100%;
                   }
               }
               .auth_form{
                   padding: 20px;
                   background-color: #fff;
                   border-radius: 0 12px 12px 0;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   @include r(1200) {
                    border-radius: 12px;
                         @include r(992) {
                          }                 
                      }
               }
           }
           .modal-title{
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 49px;
            text-align: center;            
            color: #000000;
            margin-bottom: 8px;
            
           }
           .modal-text{
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #000000;
            margin-bottom: 35px;
            
           }
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
        }

    }
    .auth-content{
        width: 400px;
    }
    &.seller-modal{
        .auth-content{
            width: 385px;
        }
        .how-to-use{
            display: flex;
            justify-content: center;
            margin-top: 40px;
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            .use-link{
                text-decoration: none;
                border-bottom: 1px dashed #1861C6;
                color: #1861C6;
            }

        }
    }
    .modal-form{
        width: 100%;
        .input-item{
            box-sizing: border-box;
            margin-bottom: 24px;
            .input{
                position: relative;
                width: 100%;
                &::after{
                    display: table;
                    content: "";
                    clear: both;
                }
                .icon{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 18px;
                    color: #A9ADB8;
                    z-index: 50;
                    left: 16px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        max-width: 100%;
                    }
                }
                .profil-input{
                    padding-left: 42px;
                    box-shadow: none;
                    outline: none;
                    &.address{
                        padding: 16px 20px;
                    }
                }
            }
            .link{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #1861C6;
                text-decoration: none;
                margin-left: 5px;
                border-bottom: 1px dashed #1861C6;
            }
            .styled-checkbox {
                position: absolute; // take it out of document flow
                opacity: 0; // hide it
              
                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                }
              
                // Box.
                & + label:before {
                  content: '';
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: text-top;
                  width: 17px;
                  height: 16px;
                  background: white;
                  background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 4px;

                }
              
                // Box hover
                &:hover + label:before {
                    background: #FFFFFF;
                }
                
              
                // Box checked
                &:checked + label:before {
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 4px;
                }
                
                // Disabled state label.
                &:disabled + label {
                  color: #b8b8b8;
                  cursor: auto;
                }
              
                // Disabled box.
                &:disabled + label:before {
                  box-shadow: none;
                  background: #ddd;
                }
              
                // Checkmark. Could be replaced with an image
                &:checked + label:after {
                  content: '';
                  position: absolute;
                  left: 4px;
                  top: 8px;
                  background: #1861C6;
                  width: 2px;
                  height: 2px;
                  box-shadow: 
                    2px 0 0 #1861C6,
                    4px 0 0 #1861C6,
                    4px -2px 0 #1861C6,
                    4px -4px 0 #1861C6,
                    4px -6px 0 #1861C6,
                    4px -8px 0 #1861C6;
                  transform: rotate(45deg);
                }
              }
        }

        .profil-input{
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
            height: 55px;
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 23px;
            color: #000;
            margin-bottom: 0;
            &::placeholder{
                color: #A9ADB8;
            }
        }   
        .label{
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;       
            margin-bottom: 8px;
        }
        .modal-button-group{
            margin-top: 40px;
            .button-list{
                display: flex;
                justify-content: center;
                gap: 16px;
                a{
                    text-decoration: none;
                    color: #FFFFFF; font-size: 18px;
                    line-height: 22px;
                    font-family: 'ProductSans-Medium';padding: 14px 19px;background: #25B2F7;
                    box-shadow: 0px 10px 15px rgba(45, 156, 210, 0.5);
                    border-radius: 8px;
                    img{
                        margin-right: 18px;
                    }
                }
                .yellow{
                    background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
border-radius: 8px;
padding: 10px 50px;
                }
            }
            .auth-accept{
                min-width: 80%;
                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                border-radius: 8px;
                color: #000000
            }
            .auth-back{
                background: #FC3F1D;
                box-shadow: 0px 10px 15px -5px rgba(252, 63, 29, 0.5);
                border-radius: 8px;
            }
        }
        .sms-time{
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            gap: 8px;
            .time{
                color: #1861C6;
            }
            
        }
    }
    .modal-top{
        position: absolute;
        right: 60px;
        top: 40px;
        z-index: 1050;
        .top-text{
            display: flex;
            align-items: center;
            gap: 8px;
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 0;
            .top-link{
                color: #FFBF3C;
                text-decoration: none;
                border-bottom: 1px dashed #FFBF3C;

            }

        }
    }
}
.filter-button-list{
    display: flex;
    margin: 15px 0;
    gap: 12px;
    flex-wrap: wrap;
    .filter-button{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #1861C6;
        box-sizing: border-box;
        border-radius: 100px;
        padding: 8px;
        padding-left: 22px;
        text-decoration: none;
        gap: 16px;
        span{
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #1861C6;

        }
        .button-icon{
            width: 24px;
            height: 24px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #1861C6;
        }
    }
    .search-button{
        background: #FFFFFF;
        border: 1px solid #1861C6;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px 28px;
        font-family: ProductSans;
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        color: #1861C6;
        &.passiv{
            border-color:#D7DDEB;
            color: #000000;

        }

    }
}