.footer{
    .footer-main{
        background: #F5F6FA;
        padding: 70px 0 58px;
        @include r(992) {
            padding: 30px 0 24px;
        }
            .footer-contact{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .contact{
                    margin-bottom: 24px;
                    h3{
                        font-family: ProductSans-Regular;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        a{
                            color: #1861C6;
                            text-decoration: none;
                            
                        }
                        span{
                            font-weight: bold;
                        }
                    }
                }
            }
            h4{
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 12px;
                color: #000000;
                font-family: ProductSans-Medium;
            }
            .socials{
                .social-list{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    list-style: none;
                    flex-wrap: wrap;
                    gap: 10px;
                
                    .social-link{
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        color: #fff;
                        font-size: 18px;
                        
                        &.telegram{
                            background: #039BE5;
                            box-shadow: 0px 3px 8px rgba(3, 155, 229, 0.3);
                        }
                        &.facebook{
                            background: #3B5998;
                            box-shadow: 0px 3px 8px rgba(59, 89, 152, 0.3);
                        }
                        &.instagram{
                            background: linear-gradient(135deg, #FADE1E 0%, #F83286 53.96%, #9843F9 100%);
                            box-shadow: 0px 3px 8px rgba(249, 55, 132, 0.3);
                        }
                        &.twitter{
                            background: #03A9F4;
                            box-shadow: 0px 3px 8px rgba(3, 169, 244, 0.3);
                        }
                        &.youtube{
                            background: #FF0000;
                            box-shadow: 0px 3px 8px rgba(255, 0, 0, 0.3);
                        }
                        &.tiktok{
                            background: linear-gradient(134.27deg, #08FFF9 0%, #F00044 98.77%);
                            box-shadow: 0px 3px 8px rgba(240, 0, 68, 0.3);
                        }
                    }
                }
            }
            .footer-center{
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                @include r(1024) {
                    justify-content: flex-start;
                    gap: 40px;
                    @include r(420) {
                        gap: 0;
                        .footer-category{
                            width: 100%;
                        }
                    }
                }
                .nav-list{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                   
                }
                .nav-item{
                    .nav-link{
                        font-size: 16px;
                        line-height: 19px;
                        color: #7A7D85;
                        padding: 0;
                        margin-bottom: 16px;
                        transition: 0.3s ease all;
                        font-family: ProductSans-Regular;    
                        &:hover{
                            color: #1861C6  ;
                        }
                    }
                }
            }
        }
    .footer-bottom{
        background: #DCDDE0;
        padding: 22px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .text{
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #000000;
        }
    }
    .footer-logo{
        margin-right: 24px;
       h4{
        margin: 0;
        color: #1B6CDD;
        font-size: 21px;
        line-height: 25px;    
        font-family: 'ProductSans-Medium';
        span{
            color: #B3B3B3; font-size: 21px;
            line-height: 25px;    
            font-family: 'ProductSans-Medium';
        }
       }
       p{
        color: #B3B3B3;font-size: 12px;
        line-height: 15px;font-family: 'ProductSans';width: 124px;
       }
    }
}
