.section{
    .search-content{
        .search-content-list{
            .search-content-products{
                .product{
                    .product-desc{
                        display: none;
                    }
                    &.active{
                        width: 45%;
                       .right{
                        display: none;
                       }
                       .left{
                        display: none;
                       }
                        .comparison-item{
                            display: none;
                        }
                        .product-desc{
                            display: block;
                            margin-bottom: 24px;           
                            .product-item {
                                background: #f5f6f6;
                                padding-top: 30px;
                                border-radius: 6px;
                                .product-photo{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin: auto;
                                    width: 70%;
                                    .icon-heart{
                                        display: none;
                                        position: absolute;
                                        top: 12px;
                                        right: 12px;
                                        font-size: 22px;
                                        line-height: 20px;
                                        color: #BDBDBD;
                                        
                                        .heart-active{
                                            display: none;
                                        }
                                        .heart-default{
                                            display: none;
                                        }
                                        &.active{
                                            .heart-default{
                                                display: none;
                                            }
                                            .heart-active{
                                                display: block;
                                                color: #FFBF3C;
                                            }
                                        }
                                    }
                                    img{
                                        padding: 8px 50px;
                                     
                                    }
                                    
                                }
                                .product-info{
                                    
                                        padding: 20px;
    
                                    .product-link{
                                        .product-info-content{
                                            a{
                                                text-decoration: none;
                                            }
                                            padding: 0;
                                            .content-img{
                                                img{
                                                    margin-right: 14px;
                                                }
                                                .content-text{
                                                    p{
                                                        color: #3D3D3D; font-size: 16px;
                                                        line-height: 19px; font-family: 'ProductSans-Medium';
                                                    }
                                                    .icon{
                                                        img{
                                                            margin: 0;
                                                        }
                                                    }
                                                }
                                            }
                                            .content-donation{
                                                margin-top: 20px;
                                                span{
                                                    color: #8F8386;font-size: 16px;
                                                    line-height: 19px;font-family: 'ProductSans';
                                                }
                                                p{
                                                    color: #414141;font-size: 24.6154px;
                                                    line-height: 30px;
                                                    font-family: 'ProductSans';
                                                }
                                            }
                                            .content-check{
                                                margin-top: 20px;
                                                img{
                                                    margin-right: 8px;
                                                    width: 13px;
                                                    height: 13px;
                                                }
                                                p{
                                                    color: #13369E;  font-size: 14px;
                                                    line-height: 17px; font-family: ProductSans;
                                                    span{
                                                        color:#8F8386;
                                                    }
                                                }
                                            }
                                            .content-contact{
                                                margin-top: 20px;
                                                .contact-text{
                                                    padding: 12px 20px;
                                                    background: #EFF5FF;
                                                    border: 1px solid #1B6CDD;
                                                    border-radius: 5px;
                                                    display: flex;
                                                    text-decoration:none;
                                                    color: #454545;font-size: 18px;
                                                    line-height: 22px;
                                                    font-family: 'ProductSans';
                                                    
                                                    img{
                                                        margin-right: 16px;
                                                    }
                                                    p{
                                                        margin-left: 10px;
                                                        color: #454545;font-size: 16px;
                                                        line-height: 19px; 
                                                        font-family: 'ProductSans';
                                                        text-decoration-line: underline;
                                                    }
                                                }
                                            }
                                            .info-footer{
                                                padding-bottom: 20px;
                                                
                                                border-bottom: 1px solid #CDC1C4;
                                            }
                                            .content-info{
                                                margin-top: 20px;
                                                img{
                                                    margin-right: 9px;
                                                    width: 14px;
                                                    height: 14px;
                                                }
                                                p{
                                                    
                                                    color: rgba(0, 0, 0, 0.5);font-size: 14px;
                                                    font-family: 'ProductSans';
                                                    line-height: 17px;
                                                }
                                            }
                                            .content-footer{
                                                padding-top: 16px;
                                                p{
                                                    font-family: 'ProductSans';
                                                    color: rgba(0, 0, 0, 0.5);
                                                    text-align: center;
                                                    font-size: 13px;
                                                    line-height: 130%;
                                                    
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .pagination-content{
                            display: none;
                        }
                    }
                }
               
           
                .filter-button-list{
                    .rectengle {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        @include r(480){
                            margin-top: 16px;
                        }
                        .rectengle-item {
                            height: 40px;
                            width: 40px;
                            margin-right: 8px;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            transition: 0.3s;
                
                            &:first-child {
                                border: 1px solid #e0e0e0;
                                border-radius: 4px;
                            }
                
                            &:last-child {
                                border: 1px solid #e0e0e0;
                                border-radius: 4px;
                                margin-left: -1px;
                            }
                
                            .square-flex {
                                display: flex;
                                align-items: center;
                                padding-left: 8px;
                
                                &:first-child {
                                    margin-bottom: 3px;
                                }
                
                                &:last-child {
                                    .line {
                                        width: 12px;
                                    }
                                }
                
                                .square {
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 2px;
                                    background: #d9d9d9;
                                    margin-right: 2px;
                                    transition: 0.3s;
                                }
                
                                .line {
                                    width: 15px;
                                    height: 3px;
                                    background: #d9d9d9;
                                    border-radius: 9px;
                                    transition: 0.3s;
                                }
                            }
                
                            .square-item {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                padding-right: 10px;
                                padding-left: 8px;
                                .square {
                                    width: 6px;
                                    height: 6px;
                                    background: #1861C6;
                                    border-radius: 1px;
                                    margin: 2px;
                                    transition: 0.3s;
                                }
                
                                .line {
                                    width: 18px;
                                    height: 3px;
                                    background: #d9d9d9;
                                    border-radius: 2px;
                                    transition: 0.3s;
                                }
                            }
                
                            &:hover,
                            &.active {
                                border: 1px solid #007fda;
                                background: #1861C6;
                
                                .square {
                                    background: #fff;
                                    
                                }
                
                                .line {
                                    background: #fff;
                                }
                
                                &:first-child {
                                    z-index: 11;
                                }
                            }
                        }
                    }
                    .abother-page{
                        padding: 12px 12px;
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        img{
                            filter: invert(80%) sepia(96%) saturate(1345%) hue-rotate(200deg) brightness(91%) contrast(100%);
                        }
                        &:hover{
                            background: #1861C6;
                            border-radius: 5px;
                            img{
                                filter: invert(9%) sepia(1%) saturate(1827%) hue-rotate(270deg) brightness(118%) contrast(100%);
                            }
                        }
                    }
                    .another-page{
                        padding: 12px 12px;
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        
                        &:hover{
                            background: #1861C6;
                            border-radius: 5px;
                            img{
                                filter: invert(100%) sepia(96%) saturate(14%) hue-rotate(310deg) brightness(104%) contrast(100%);    
                            }
                        }
                    }
                }
                .comparison-item{
                    margin-top: 30px;
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 4px;
                    &:last-child{
                        margin-bottom: 20px;
                    }
                    .search-product-item{
                        border:none;
                        .comparison-infos{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 53%;
                            padding: 24px 20px 30px;
                            .comp-infos{
                                text-decoration:none;
                                display: flex;
                                img{
                                    width: 13px;
                                    height: 13px;
                                    margin-right: 8px;
                                    margin-top: 2px;
                                }
                                p{
                                    color: #13369E;font-size: 14px;
                                    line-height: 17px;
                                    font-family: ProductSans;
                                    span{
                                       color: #000000; 
                                    }
                                }
                            }
                            
                        }
                    }
                    .infos-footer{
           
                       
                       
                        padding: 0px 20px 5px;
                        img{
                            background: #FFFFFF;
                            border: 0.40625px solid #D7DDEB;
                            border-radius: 41.8438px;
                        }
                        .product-title{
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            width:464px;
                            font-size: 18px;
                            line-height: 22px;
                            color: #000000;
            
                        }
                        .product-text{
                            font-family: ProductSans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                        }
                        .tel{
                            display: flex;
                            flex-direction:row;
                            justify-content: space-between;
                            @include r(768){
                                display: block !important;
                            }
                            .shop-info{
                                margin-top: 10px;
                                .shop-top{
                                    display: inline-flex;
                                    gap: 6px;
                                    align-items: center;
                                    margin-bottom: 8px;
                                    span{
                                        font-family: ProductSans;
                                        font-size: 14px;
                                        line-height: 17px;
                                        color: #7A7D85;
                                    }
                                   
                                }
                                .stars-content{
                                    margin-left: 8px;
                                    .shop-title{
                                        font-family: ProductSans-Medium;
                                        font-size: 16px;
                                        line-height: 19px;
                                        color: #3D3D3D;
                                        margin-bottom: 8px;
                                        img{
                                            margin-right: 4px;
                                        }
                                    }
                                }
                                .stars-list{
                                    line-height: 16px;
                                  
                                }
                            }
                            .content-contact{
                                margin-top: 20px;
                                .contact-text{
                                    padding: 12px 20px;
                                    background: #EFF5FF;
                                    border: 1px solid #1B6CDD;
                                    border-radius: 5px;
                                    display: flex;
                                    text-decoration:none;
                                    color: #454545;font-size: 18px;
                                    line-height: 22px;
                                    font-family: 'ProductSans';
                                    @include r(1024){
                                        padding: 10px 20px;
                                        font-size: 16px;
                                    }
                                    @include r(768){
                                        padding: 8px 16px;
                                        font-size: 14px;
                                        width: 60%;
                                        margin-bottom: 20px;
                                    }
                                    img{
                                        margin-right: 16px;
                                    }
                                    p{
                                        margin-left: 10px;
                                        color: #454545;font-size: 16px;
                                        line-height: 19px; 
                                        font-family: 'ProductSans';
                                        text-decoration-line: underline;
                                    }
                                }
                            }
                        }
                        @include r(768) {
                            width: 100%;
                            padding: 0 20px 0;
                        }
                    }
                }
            }
        }
    }
}