.product-item{
    &:hover{
        .icon-heart{
            display: block;
        }
        .btn:hover{
            border: none;
        }
    }
    .icon-heart{
        display: none;
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 22px;
        line-height: 20px;
        color: #BDBDBD;
        .heart-active{
            display: none;
        }
        &.active{
            .heart-default{
                display: none;
            }
            .heart-active{
                display: block;
                color: #FFBF3C;
            }
            
        }
    }
    
    .product-photo{
        position: relative;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 5px;
        overflow: hidden;
        height: 220px;
        padding: 16px;
        margin-bottom: 12px;
        @include r(992) {
            height: 220px;
            @include r(420) {
                font-size: 12px;
                height: 180px;
                margin-bottom: 6px;
            }
        }
        img{
            max-width: 100%;
        }
        &.border-img{
            border-color: #ededed;
        }
        .discount{
            position: absolute;
            left: 14px;
            bottom: 14px;
            z-index: 999;
            background-color: #FC3F1D;
            background: #FC3F1D;
            border-radius: 8px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            span{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
                &.icon{
                    transform: rotateY(180deg);
                    margin-right: 5px;
                }
            }
        }
        
       
       
        .term-payment{
            position: absolute;
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
            background: #1861C6;
            border-radius: 4px;
            padding: 4px 8px;
            top: 12px;
            left: 12px;

        }
    }
    .product-info{
        .product-link{
            color: none;
            display: inline-block;
            text-decoration: none !important;
            &:hover{
                text-decoration: none !important;
            }
            .info-img{
                margin-top: 10px;
                img{
                    margin: 9px 12px 0 0;
                  width: 17px;
                  height: 17px;  
                }
                .content-text{
                    p{
                        color: rgba(0, 0, 0, 0.5);font-size: 14px;
                        line-height: 17px;font-family: ProductSans;
                    }
                    .icon{
                        margin-top: 1px;
                        img{
                            margin: 0;
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
            }
            .product-title{
                font-size: 18px;
                line-height: 22px;
                font-weight: 500;
                margin: 0;
                color: #000;
                transition: 0.2s ease all;
                font-family: ProductSans-Medium;
                margin-bottom: 8px;
                @include r(992) {
                    font-size: 16px;
                    line-height: 20px;
                    @include r(420) {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 0px;
                    }
                }
            }
            .price{
                position: absolute;
                color: #7A7D85;
            }
            .product-price{
                color: red;
            }
            .discount-price{
               
                margin: 0;
                
                font-size: 14px;
                line-height: 17px;
                font-family: ProductSans-Medium;
                margin-bottom: 4px;
                @include r(992) {
                    font-size: 13px;
                    @include r(420) {
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
               
            }
            .discount{
                left: 100px;
                bottom: 2px;
                position: relative;
                text-decoration: none;
                z-index: 999;
                background-color: #FC3F1D;
                background: #FC3F1D;
                border-radius: 8px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                span{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: #FFFFFF;
                    &.icon{
                        transform: rotateY(180deg);
                        margin-right: 5px;
                    }
                }
            }
            .pice{
                color: #1861C6;
                font-size: 24px;
                line-height: 29px;
                margin: 0;
                font-family: ProductSans-Medium;
                @include r(992) {
                    font-size: 20px;
                    line-height: 25px;

                    @include r(420) {
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }

            }
            
        }
        .shop-info{
            margin-top: 10px;
            .shop-top{
                display: inline-flex;
                gap: 6px;
                align-items: center;
                margin-bottom: 8px;
                span{
                    font-family: ProductSans;
                    font-size: 14px;
                    line-height: 17px;
                    color: #7A7D85;
                }
                .shop-title{
                    font-family: ProductSans;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.5);
                    text-decoration: none;
                    img{
                        margin-right: 12px;
                    }
                }
            }
            .stars-list{
                line-height: 16px;
                margin-left: 40px;
            }
        }
        &:hover{
          
            .product-title{
                color: #FC3F1D;
            }
        }
     
    }
}

.product-section{
    .product-item{
        margin: 15px 0;
        @include r(992) {
            margin: 10px 0;
            
        }
    }
    margin-bottom: 60px;
    @include r(992) {
        margin-bottom: 30px;
    }
}
.product-share-list{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;
    z-index: 999;
    flex-wrap: wrap;
    @include r(992) {
        gap: 10px;
        .stars-content{
            margin-bottom: 0;
        }
    }
    .stars-content{
        .stars-list{
            display: flex;
            align-items: center;
            gap: 5px;
            .star-item{
                font-size: 16px;
                color: #D7DDEB;
                &.active{
                    color: #FFBF3C;
                }
            }
            .star-count{
                font-family: ProductSans-Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #7A7D85;
                margin-left: 4px;
            }
        }
    }
    .product-share-list{
        .product-share-link{
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #1861C6;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 6px;
            .icon{
                font-size: 18px;
                &.rotate{
                    transform: rotateY(180deg);
                }
            }
        }
    }
}


.owl-carousel-vertical{
    transform: rotateZ(90deg);
    transform-origin: bottom left;
    position: relative;
    z-index: 999;
    width: 500px;
    .owl-item{
        width: 80px ;
       
    }
    @include r(768) {
        transform: none;
        width: 90%;
        margin: 0 auto;
        transform-origin: top left;
        .owl-item{
            width: auto !important;
            @include r(576) {
                width: 80px !important;
            }
        }
    }
    
    
  }
  
  .owl-carousel-vertical .item{
    transform: rotateZ(-90deg);
    background: #FFFFFF;
    border: 1px solid #D7DDEB;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include r(768) {
        transform: none;
        
        @include r(576) {
            width: auto;
        }
    }
  }

  .owls{
    .owl-carousel-horizontal { 
        position: relative; 
        padding-left: 97px;
        width: 100%; 
        z-index: 99;
        margin: 0 auto;
        .owl-nav {
            display: none;
        }
        @include r(768) {
            padding-left: 0px;
            margin-top: 15px;
        }
        
    }
      .owl-stage-outer { 
          overflow: hidden; 
        }
      .owl-carousel-vertical{
        margin-top: -40px;
        @include r(768) {
            margin-top: 20px;
        }
        .owl-stage-outer { 
            overflow: hidden; 
        }
        .owl-nav {
            display: none;
           position: absolute;
            top: 0;
            left: 0;
            right: 0; align-items: center;
            height: 100%; 
            display: flex;
            z-index: -1;}
        .owl-prev, .owl-next { 
          display: flex; 
          justify-content: center; 
          align-items: center; 
          position: absolute; 
          z-index: 1099;
          width: 40px;
          height: 40px; 
          font-size: 18px;
          cursor: pointer; 
        }
        .owl-prev { 
            right: 100%; 
            @include r(479){
                right: 98%;
            }
            @include r(420){
                right: 97%;
            }
        }
        .owl-next { 
            left: 100%; 
            @include r(479){
                left: 98%;
            }
            @include r(420){
                left: 97%;
            }
            @include r(387){
                left: 96%;
            }
        }
        }
        .owl-item { 
            display: inline-block;
         }
          .item { 
              height: 80px;
              width: 80px;
             text-align: center; 
            }

            .item-nested {
                height: 500px; 
                text-align: center; 
                @include r(768) {
                    width: 100% !important; 
                    height: 400px;
                    @include r(576) {
                        height: 300px;
                    }
                }
                .item-main{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 4px;
                    @include r(768) {
                        width: 100% !important; 
                        img{
                            max-height: 100%;
                        }
                    }
                }
              img{
                  object-fit: contain;
              }
            }
            .item img{
                max-width: 100%;
            }
            .item span { 
                display: flex; 
                justify-content: center; 
                align-items: center; 
                width: 100%;
                 height: 100%;
                  border-radius: 6px; 
                  background-color: #0c83e7; 
                  color: #fff; 
                  font-size: 24px; 
                  line-height: 1em; 
                }            
            .owl-carousel-vertical .owl-item span { cursor: pointer; }
            .owl-carousel-vertical .owl-item.current .item{ 
                border-color: #FFBF3C; 
            }
    
          .owl-carousel-horizontal .item { display: flex; height: 430px; text-align: left; }

            
            .owl-carousel-horizontal .item .text { padding: 20px; font-size: 48px; }
              .owl-carousel-horizontal .item h2 { font-size: 48px; }
              .owl-carousel-horizontal .item p { font-size: 16px; line-height: 1.3em; }
    
    
      .owl-dots { margin: 0 auto; text-align: center;  display: none;}
        .owl-dot { display: inline-block; padding: 10px; text-align: center; font-size: 0; line-height: 0; cursor: pointer; }
          .owl-dot span { display: inline-block; width: 8px; height: 8px; border-radius: 50%; background-color: #888; }
          .owl-dot.active span { background-color: #000; }
  }
  .one-order-card-header{
    
    margin-top: 37px;
    background: #F5F6FA;
    border-radius: 8px;
    padding: 30px;
    @include r(768) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    @include r(576){
        margin: 0;
    }
    @include r(479){
        width: 98% !important;
        margin: 0 !important;
    }
    @include r(420){
        margin:  0;
        width: 100%;
    }
    
    .order-header-img{
       
        background: #FFFFFF;
        border-radius: 103px;
        max-width: 128px;
        max-height: 128px;margin: 0 80px;
        @include r(1024){
            margin: 0;
            margin-left: 55px;    
        }
        @include r(768){
            margin: 24px 0 24px 0;
        }
        img{
            padding: 52px 13px;
            
        }
        h4{
            width: 200px;
            color: #3D3D3D;font-size: 16px;
            line-height: 19px;
            margin-left: -30px;
            font-family: 'ProductSans-Medium';font-weight: 500;
        }
        .icon {
            padding: 0;
          max-width: 16px;
          max-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                
                max-width: 100%;
                max-height: 100%;
                padding: 0;
                &:first-child{
                    margin-left: 110px;
                }
            }
           
        }
    }
        .content-infos{
            margin-top: 20px;
            img{
                margin-right: 9px;
                width: 14px;
                height: 14px;
            }
            p{
                color: rgba(0, 0, 0, 0.5);font-size: 14px;
                font-family: 'ProductSans';
                line-height: 17px;
                opacity: 1 !important;
            }
           
        }
        .info-footer{
            padding-bottom: 15px;
            border-bottom   : 1px solid rgba(0, 0, 0, 0.5);
        }
        .content-footer{
            padding-top: 16px;
            
            p{
                font-family: 'ProductSans';
                color: rgba(0, 0, 0, 0.5);
                text-align: center;
                font-size: 13px;
                line-height: 130%;
                
            }
        }
    
    .comparison-infos{
        
        .comp-infos{
            margin-bottom: 10px;
            @include r(480){
                padding: 10px;
            }
            
            img{
                margin-right: 8px;
            }
            p{
                color: #13369E;font-size: 14px;
                font-family: 'ProductSans';
                line-height: 17px;
                opacity: 1 !important;
                span{
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 14px;
                    font-family: 'ProductSans';
                    line-height: 17px; 
                    margin-left: 6px;
                }
            }
        }
    }
    .discount-price-footer{
        .content-img{
            img{
                margin-right: 8px;
            }
            .icon{
                img{
                    margin: 0;
                }
            }
        }
        .content-contact{
            margin-top: 20px;
            padding: 0 0 15px 0;
            .contact-text{
                padding: 12px 20px;
                background: #EFF5FF;
                border: 1px solid #1B6CDD;
                border-radius: 5px;
                display: flex;
                text-decoration:none;
                color: #454545;font-size: 18px;
                line-height: 22px;
                font-family: 'ProductSans';
                @include r(1024){
                    margin-top: 8px;
                    padding: 12px 10px;
                    font-size: 15px;
                    line-height: 22px;
                }
                img{
                    margin-right: 16px;
                    @include r(1024){
                        width: 22px;
                        height: 22px;
                        
                    }
                }
                p{
                    margin-left: 10px;
                    color: #454545;font-size: 16px;
                    line-height: 19px; 
                    font-family: 'ProductSans';
                    text-decoration-line: underline;
                }
                span{
                    display: none;
                }
            }
            .active{
                p{
                    display: none;
                }
                span{
                    margin-left: 10px;
                    display: block;
                }
            }
        }
    }
    .discount-price-div{
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 85px;
        .discoun-price{
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: line-through;
            color: #7A7D85;

        }
        .discount{
            z-index: 999;
            background-color: #FC3F1D;
            background: #FC3F1D;
            border-radius: 8px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
            width: 60px;
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            .icon{
                font-size: 10px;
                transform: rotateY(180deg);
            }
        }
    }
    p{
        color: #000000;  font-size: 16px;
        font-family: ProductSans;
        line-height: 19px;opacity: 0.5;
    }
    .order-price{
        font-family: ProductSans;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #1B6CDD;
        margin: 0;
    }
    .order-info-list{
        .order-info-tem{
            margin-bottom: 16px;
            .title{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #13369E;
                margin-bottom: 8px;

            }
       
            p{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                margin-top: 16px;
            }
            h3{
                color: #414141; font-size: 24.6154px;
                line-height: 30px;
                font-family: 'ProductSans';
            }
        }
    }
    .order-buttons{
        margin-top: 48px;
        .order-button{
            width: 100%;
            height: 56px;
            background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
            box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
            border-radius: 8px;
            font-family: ProductSans-Medium;
            font-style: normal;
            display: flex;align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            &.yellow{
                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                color: #000000;
            }
        }
        .list-button{
            display: flex;
            gap: 12px;
            margin-bottom: 24px;
            .buy-button{
                width: 80%;
            }
            .card-button{
                width: 20%;
            }
        }
    }
}
.one-order-card{
    margin-top: 37px;
    background: #F5F6FA;
    border-radius: 8px;
    padding: 30px;
    .shop-logo{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        background: #fff;
        img{
            max-width: 100%;
            min-height: 100%;
            object-fit: contain;
        }
    }
    .discount-price-footer{
        .content-img{
            img{
                margin-right: 8px;
                
            }
            .icon{
                img{
                    margin: 0;
                   
                }
            }
        }
        .content-contact{
            margin-top: 20px;
            padding: 0 0 15px 0;
            .contact-text{
                padding: 12px 20px;
                background: #EFF5FF;
                border: 1px solid #1B6CDD;
                border-radius: 5px;
                display: flex;
                text-decoration:none;
                color: #454545;font-size: 18px;
                line-height: 22px;
                font-family: 'ProductSans';
                &.active{
                    p{
                        display: none;
                    }
                    span{
                        display: block;
                        padding-left: 10px;
                    }
                }
                @include r(1024){
                    margin-top: 8px;
                    padding: 12px 10px;
                    font-size: 15px;
                    line-height: 22px;
                }
                img{
                    margin-right: 16px;
                    @include r(1024){
                        width: 22px;
                        height: 22px;
                        }
                }
                p{
                    margin-left: 10px;
                    color: #454545;font-size: 16px;
                    line-height: 19px; 
                    font-family: 'ProductSans';
                    text-decoration-line: underline;
                }
                span{
                    display: none;
                }
            }
        }
    }
    .discount-price-div{
        display: flex;
        align-items: center;
        gap: 8px;
        .discoun-price{
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: line-through;
            color: #7A7D85;

        }
        .discount{
            z-index: 999;
            background-color: #FC3F1D;
            background: #FC3F1D;
            border-radius: 8px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
            width: 60px;
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
            .icon{
                font-size: 10px;
                transform: rotateY(180deg);
            }
        }
    }
    p{
        color: #000000;  font-size: 16px;
        font-family: ProductSans;
        line-height: 19px;opacity: 0.5;
    }
    .order-price{
        font-family: ProductSans;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #1B6CDD;
        margin: 0;
    }
    .order-info-list{
        .order-info-tem{
            margin-bottom: 16px;
            .title{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #13369E;
                margin-bottom: 8px;

            }
       
            p{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #000000;
                margin-top: 16px;
            }
            h3{
                color: #414141; font-size: 24.6154px;
                line-height: 30px;
                font-family: 'ProductSans';
            }
        }
    }
    .order-buttons{
        margin-top: 48px;
        .order-button{
            width: 100%;
            height: 56px;
            background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
            box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
            border-radius: 8px;
            font-family: ProductSans-Medium;
            font-style: normal;
            display: flex;align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            &.yellow{
                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                color: #000000;
            }
        }
        .list-button{
            display: flex;
            gap: 12px;
            margin-bottom: 24px;
            .buy-button{
                width: 80%;
            }
            .card-button{
                width: 20%;
            }
        }
    }
}
.about-products{
    margin-top: 38px;
    margin-bottom: 70px;
    @include r(768) {
        margin-top: 16px;
    }
   
    .title{
        font-family: ProductSans-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 16px;
    }
    .settings{
        font-family: ProductSans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #1861C6;
        text-decoration: none;
    }
}
.products-page{
    .about-list{
        margin-bottom: 16px;
        position: relative;
        p {
            position: relative;
            background: inherit;
            margin: 0 auto;
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 10px;
            span{
                position: relative;
                background-color: #fff !important;
                z-index: 999 !important;
            }
          }
          p:before {
            content: '';
            position: absolute;
            bottom: 2px;
            width: 100%;
            height: 0;
            line-height: 0;
            border-bottom: 2px dotted #ddd;
          }
          .descripcion {
            display: inline-block;
            padding-right: .2rem;
          }
          .precio {
            position: absolute;
            bottom: -1px;
            right: 0;
            padding-left: .2rem;
            text-align: right;
            z-index: 2;
          }
    }
    .order-4{
        display: flex;
        justify-content: flex-end;
    }
    .pages_card_content{
        .about-list{
            .precio{
                padding-right: 20px;
            }
        }
    }
}
.card-title{
    font-family: ProductSans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 24px;
    @include r(992) {
        margin-bottom: 10px;
        line-height: 24px;
    }
    @include r(768) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
    }
}
.products-page .descriptions{
    margin-bottom: 32px;
    padding-top: 120px;

    @include r(992) {
        padding-top: 0;
    }
    .descriptions-text{
        font-family: ProductSans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 22px;
        color: #000000;
    }
    a{

        text-decoration: none;
        color: #1B6CDD;font-size: 18px;
        line-height: 22px; font-family: ProductSans;
    }
}
.specifications{
    .specification-content{
        .title{
            font-family: ProductSans-Medium;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            color: #000000;

        }
        .row{
            margin-bottom: 30px;
            @include r(992) {
               
                margin-bottom: 0px;
            }
        }
    }
}
.product-feedback{
    .block-title{
        margin-bottom: 24px;
        .title{
            display: flex;
            align-items: center;
            gap: 16px;
        }
        .count{
            color: #A9ADB8;
            font-weight: 400;
            font-family: ProductSans;
        }
        @include r(992) {
            margin-bottom: 16px;
           
        }

    }
    .feedback-list{
        .feedback-item{
            display: flex;
            position: relative;
            margin-bottom: 30px;
            @include r(992) {
                margin-bottom: 20px;
               
            }
        }
        .feedback-img{
                position: absolute;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    position: relative;
                    z-index: 999;
                }
        }
        .feedback-info{
            margin-left: 65px;
           
            .feedback-top{
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 9px;
                .user-name{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                    margin-bottom: 0;
                }
                .date{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #A9ADB8;
                }
            }
            .feedback-text{
                p{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 0;
                    color: #000000;
                }
            }
        }
    }
    .feedback-more{
        display: flex;
        padding-left: 60px;
        justify-content: start;
        .more-button{
            border: 1px solid #A9ADB8;
            box-sizing: border-box;
            box-shadow: 0px 10px 15px -5px rgba(221, 218, 218, 0.904);
            border-radius: 8px;
            padding: 17px 35px;
           
            span{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #A9ADB8;
            }
            @include r(768) {
                padding: 10px 35px;
                span{
                    font-size: 16px;
                }
               
            }
        }
    }
    .card-feedback{
        padding-top: 72px;
        .feedback-leave-button{
            background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
            box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
            border-radius: 8px;
            width: 100%;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 32px;
            @include r(768) {
                margin-top: 20px;
                padding: 17px 35px;
                margin-bottom: 20px;
               
            }
            span{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #000000;
            }
        }
        .rated-reviews-list{
            .title{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 16px;
            }
        }
    }
}
.categories-products{
    margin-top: -15px;
    margin-bottom: 20px;
    .product-item{
        margin: 15px 0;
        
    }
    @include r(992) {
            margin-bottom: 15px;
            margin-top: -8px;
            .product-item{
                margin: 8px 0;
            }
        }
}
.pagination-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @include r(992) {
        margin-bottom: 10px;
        flex-wrap: wrap;
        gap: 20px;
    }
    @include r(576){
        display: none;
    }
    .pagination{
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 0;
        .page-item{
            .page-link{
                font-family: ProductSans;
                display: inline-flex;
                gap: 12px;
                align-items: center;
                justify-content: center;
                align-items: center;
                padding: 15px 25px;
                border: 1px solid #A9ADB8;
                box-sizing: border-box;
                box-shadow: 0px 10px 15px -5px rgba(219, 216, 208, 0.5);
                border-radius: 8px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                
                
                .icon{
                    font-size: 14px;
                    }
                @include r(992) {
                    font-size: 14px;
                    padding: 8px 12px;
                    line-height: 16px;
                    gap: 6px;
                    .icon{
                        font-size: 10px;
                        line-height: 10px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .page-link:hover{
                background-color: #1861C6;
                color: #fff;
                box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
                border-radius: 8px;
                border-color: #1861C6;
    
            }
            .page-link.active{
                background-color: #1861C6;
                color: #fff;
                border-color: #1861C6;
                box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
                border-radius: 8px;
            }
            .first,.last{
                width: auto !important;
            }
        }
    }
    
    .paginate-select{
        height: 50px;
        border: 1px solid #A9ADB8;
        box-sizing: border-box;
        box-shadow: 0px 10px 15px -5px rgba(219, 216, 208, 0.5);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        .current{
            
font-family: 'ProductSans';font-size: 16px;
line-height: 19px;color: #000000;   
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .list{
            width: 100%;
            padding: 10px;
            margin: 3px 0 0;
            .option{
                padding: 5px ;
                &.selected {
                    color: #999;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    background-color: #fff;
                }
                &:hover{
                    color: #1861C6;
                    background-color: #fff;

                }
            }
        }
        @include r(992) {
            height: 35px;
            font-size: 14px;
        }
    }
}
.stars-content{
    margin-bottom: 8px;
    .stars-list{
        display: flex;
        align-items: center;
        gap: 5px;
        .star-item{
            font-size: 16px;
            color: #D7DDEB;
            &.active{
                color: #FFBF3C;
            }
        }
        .star-count{
            font-family: ProductSans-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-left: 4px;
        }
    }
}

