.about{
    .breadcrumb{
        margin-top: 22px;
    }
   
    .about-title{
        h2{
            margin: 16px 0px 24px;
            color: #000000; font-size: 35px;
            line-height: 42px;font-family: 'ProductSans';
            @include r(768){
                font-size: 28px;
            }
            @include r(576){
                font-size: 26px;
            }
            @include r(420){                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  

                font-size: 26px;
            }
            @include r(360){
                margin: 16px 0 0;
            }
        }
        p{
            color: #000000;font-size: 16px;
            line-height: 24px;font-family: 'ProductSans-Medium';
        }
    }
    .about-banner{
        background: #13369E;
        border-radius: 12px;
        img{
         margin-top: 60px;
            @include r(1024){
                
                margin-top: 50px;
                width: 95%;
            
            }
            @include r(576){
                margin-top: 60px;
                width: 94%;
            }
            @include r(480){
                margin-top: 60px;
                width: 100%;
            }
            @include r(420){
                display: none;
            }

        }
        .about-banner-title{
              h2{
                padding: 135px 0 32px 50px;
                color: #FFFFFF;font-size: 60px;
                line-height: 73px;font-family: 'ProductSans-Black';
                @include r(1024){
                    font-size: 40px;
                    padding: 115px 0 32px 50px;
                    line-height: 62px;
                }
                @include r(768){
                    margin-top: 60px;
                    font-size: 34px;
                    line-height: 40px;
                    padding: 8px 0 32px 20px;
                }
                @include r(576){
                    margin-top: 38px;
                    font-size: 27px;
                    line-height: 36px;
                    padding: 8px 0 32px 20px;
                }
                @include r(576){
                    margin-top: 28px;
                }
                
            }
            p{
                padding: 0 0 162px 50px;
                color: #FFFFFF; font-size: 18px;
                line-height: 26px;font-family: 'ProductSans';
                @include r(1024){
                    display: none;
                }
            }
        }
        .about-text{
            position: relative;
            bottom: 70px;
            left: 115px;
            background: #FFBF3C;
            border-radius: 10px;
            max-width: 470px;
            @include r(1024){
                
                position: relative;
                left: 49px;
                bottom: 36px;
                max-width: 360px;
            }
            @include r(992){
                              
                max-width: 330px;
            }
            @include r(768){
               display: none;
            }
            @include r(576){
                display: none;
            }
            }
            p{
                padding: 16px;
                max-width: 470px;color: #000000;
                line-height: 17px;
                font-size: 14px;
                font-family: 'ProductSans';
               
            }
        }
        .contacts{
            .contacts-item{
                margin-top: 48px;
                img{
                    margin-top: 6px;
                    max-width: 23px;
                    max-height: 17px;
                    margin-right: 12px;
                }
                h4{
                    color: #1861C6;line-height: 29px;font-size: 24px;font-family: 'ProductSans';
                    margin: 0;
                    @include r(992){
                        font-size: 20px;
                    }
                    @include r(768){
                        font-size: 18px;
                    }
                    @include r(420){
                        font-size: 13px;
                    }
                }
                p{
                    padding-top: 8px;
                    color: #000000;font-size: 16px;
                    line-height: 19px;font-family: 'ProductSans-Medium';
                    @include r(768){
                        font-size: 13px;
                    }
                    @include r(420){
                        padding: 0;
                        font-size:10px;
                       
                    }
                    
                }
            } 
        }
        .reports-body{
            .reports{
                margin-top: 70px; 
                @include r(768){
                 margin-top: 40px;
                }
                 img{
                     margin-top: 6px;
                     max-width: 70px;
                     max-height: 70px;
                     margin-right: 25px;
                     @include r(768){
                         max-width: 60px;
                     max-height: 60px;
                     }
                 }
                 .reports-item{
                     h4{
                         color: #1861C6;line-height: 55px;font-size: 45px;font-family: 'ProductSans-Medium';
                         margin: 0;
                         @include r(992){
                            font-size: 40px;
                            line-height: 47px;
                         }
                         @include r(768){
                             font-size: 36px;
                             line-height: 42px;
                        }
                        @include r(576){
                            font-size: 28px;
                            line-height: 38px;
                       }
                    }
                    p{
                        color: #000000;font-size: 16px;
                        line-height: 24px;font-family: 'ProductSans';
                    }
             }
        }
}

    .about-item{
        h4{
            color: #000000;font-size: 45px;
            line-height: 55px;font-family: 'ProductSans-Medium';
            margin-top: 70px;
            @include r(768){
                width: 75%;
                font-size: 36px;
                line-height: 42px;
                margin-top: 40px;
            }
            @include r(420){
                width: 75%;
    font-size: 23px;
    line-height: 42px;
    margin-top: 25px;
            }
        }
        p{
            width: 85%;
            margin-top: 16px;
            color: #000000;font-size: 16px;
            line-height: 24px;font-family: 'ProductSans-Medium';
            @include r(420){
                width: 85%;
                margin-top: 16px;
                color: #000000;
                font-size: 13px;
                line-height: 22px;
                font-family: 'ProductSans-Medium';
            }
        }
    }
    .reports{
        margin-top: 70px; 
        @include r(768){
         margin-top: 40px;
        }
         img{
             margin-top: 6px;
             max-width: 70px;
             max-height: 70px;
             margin-right: 25px;
             @include r(768){
                 max-width: 60px;
                max-height: 60px;
             }
             
         }
         .reports-item{
             h4{
                 color: #1861C6;line-height: 55px;font-size: 45px;font-family: 'ProductSans-Medium';
                 margin: 0;
                 @include r(992){
                    font-size: 40px;
                    line-height: 47px;
                 }
                 @include r(768){
                     font-size: 36px;
                     line-height: 42px;
                }
                @include r(576){
                    font-size: 23px;
                    line-height: 38px;
               }
            }
            p{
                color: #000000;font-size: 16px;
                line-height: 24px;font-family: 'ProductSans';
            }
     }
}
    .mb-70{
        margin-bottom: 70px;
    }
    .mt-70{
        margin-top: 70px;
        @include r(576){
            margin-top: 40px;
        }
    }
    
    .terms{
        p{
            color: #000000;font-size: 16px;
            line-height: 26px;font-family: 'ProductSans-Medium';
        &:last-child{
            margin-bottom: 70px;
        }
        }
        
        .terms-menu{
            background: #F5F6FA;
            border-radius: 8px;
            &:last-child{
                padding-bottom: 24px;
            }
            a{
                display: inline-block;
                padding: 24px 0 0 20px;
                text-decoration: none;
                color: #13369E; font-size: 16px;
                line-height: 19px;font-family: 'ProductSans-Medium';
            }
            p{
                padding: 16px 66px 0 32px;
                color: #000000;font-size: 14px;
                line-height: 17px;font-family: 'ProductSans';

            }
        }
        .terms-body{
            .mt-24{
                margin-top: 24px;
            }
            .mb-70{
                margin-bottom: 70px;
            }
            .terms-body-title{
                h4{
                    color: #1861C6;font-size: 24px;
                    line-height: 29px;
                    font-family: 'ProductSans';
                    @include r(576){
                        margin-top: 20px;
                    }
                }
                p{
                    color: #000000;font-size: 16px;
                    line-height: 26px;font-family: 'ProductSans-Medium';
                }
              
            }
        }
        .bank-card{
            margin: 38px 0 48px;
            img{
                width: 100%;
               @include r(479){
                width:80%;
                margin-left: 20px;
               }
            }
            h4{
                color: #1861C6; 
                 font-size: 24px;
                line-height: 29px;
                font-family: 'ProductSans';
                @include r(768){
                    font-size: 22px;
                }
                
            }
            p{
                color: #000000;font-size: 16px;
                line-height: 19px;font-family: 'ProductSans-Medium';
                @include r(768){
                    font-size: 12px;
                }
            }
        }
    }
    .partner-steps-block{
        padding: 60px 0;
        @include r(1024) {
            padding: 30px 0;
            @include r(992) {
                padding: 20px 0;
            }
        }
        .step-list{
            
            @include r(1024) {
                margin-top: 30px;
                @include r(992) {
                    margin-top: 20px;
                }
            }
        }
        .step-item{
            text-align: center;
            @include r(992) {
                margin-bottom: 20px;
            }
            .item-photo{
                margin-bottom: 24px;
                position: relative;
                @include r(992) {
                   height: 150px !important;
                    position: relative;
                    margin-bottom: 10px;
                   img{
                       height: 100%;
                   }
                }
                .step-arrow{
                    position: absolute;
                    top: 50%;
                    left: 100% ;
                  transform: translate(-50%, -50%);
                  @include r(768) {
                    display: none;
                }
                }
            }
            .item-text{
                .title{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: center;
                    color: #000000;
                    margin-bottom: 8px;
                    @include r(992) {
                        font-size: 22px;
                        line-height: 25px;
                    }
                }
                .text{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    margin-bottom: 0;
                    color: #000000;
                    @include r(768){
                        font-size: 14px;
                    }
                }
            }

        }
        .for-button{
            margin-top: 60px;
            display: flex;
            justify-content: center;
            @include r(1024) {
                margin-top: 20px;
                margin-bottom: 10px;

            }
            @include r(992) {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}
.our-mission{
    background: #F5F6FA;
    .our-mission-title{
        h4{
            margin-top: 70px;
            color: #000000;font-size: 45px;
            line-height: 55px;
            font-family: 'ProductSans';
            @include r(768){
                margin-top: 40px;       
                font-size: 36px;
                line-height: 42px;

            }
            @include r(420){
                margin-top: 28px;
                font-size: 34px;
            }
            @include r(360){
                margin-top: 20px;
                font-size: 30px;
            }
        }
    }
    .our-mission-text{
        p{
            margin: 70px 0;
            color: #000000;  font-size: 16px;
            line-height: 26px;font-family: 'ProductSans-Medium';
            @include r(768){
                margin: 40px 0;
            }
            @include r(420){
                margin: 20px 0;
            }
        }
    }

} 
.address{
    margin-top: 70px;
    max-width: 1920px;
    height: 500px;
    .map{
        width: 100%;
        height: 100%;
    }
}