.shops-page {
    padding: 32px 0;

    @include r(1024) {
        padding: 20px 0;

    }

    .shops-content {
        .shops-content-banner{
            @include r(768){
                isplay: flex !important;
                justify-content: center;
            }
        }
        .shops-content-top {
            .catalog-result {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-bottom: 32px;

                @include r(576) {
                    margin-bottom: 20px;
                }

                .result-counts {
                    font-family: ProductSans-Bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #000000;
                }

                .result-more {
                    font-family: ProductSans;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: none;
                    color: #1861C6;
                    border-bottom: 1px dashed #1861C6;
                }
            }

            .catalog-list {
                @include r(992){
                    max-width: 925px;
                }
                @include r(768){
                    max-width: 690px !important;
                }
                a {
                    text-decoration: none;
                }

                .slick-slide {
                    outline: none;

                    &:focus {
                        outline: none;
                    }
                }

                .catalog-item {
                    background: #F5F6FA;
                    border-radius: 8px;
                    padding: 12px 9px;
                    transition: .3s ease all;
                    // margin: 0 10px;
                    height: 170px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: space-between;
                    user-select: none;

                    &:focus {
                        outline: none;
                    }

                    .catalog-title {
                        font-family: ProductSans-Bold;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 17px;
                        text-align: center;
                        color: #13369E;
                        margin-bottom: 2px;
                        transition: .3s ease all;

                    }

                    .product-count {
                        font-family: ProductSans;
                        font-size: 13px;
                        line-height: 15px;
                        text-align: center;
                        color: #7A7D85;
                        margin-bottom: 8px;
                        transition: .3s ease all;

                    }

                    .product-photo {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    &:hover {
                        background: #FFBF3C;

                        .catalog-title {
                            color: #000000;
                        }

                        .product-count {
                            color: #fff;
                        }
                    }
                }

                .slick-prev {
                    z-index: 11;
                    width: 50px;
                    height: 50px;

                    top: 50%;
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 50%;

                    &:focus {
                        background: #F5F6FA;

                    }

                    &::before {
                        opacity: .8 !important;
                        content: url(../img/left.svg);
                    }

                    &:hover {
                        background: #1861C6;
                        opacity: 1 !important;

                        &::before {
                            opacity: 1 !important;
                            -webkit-filter: brightness(0) invert(1);
                            filter: brightness(0) invert(1);

                        }
                    }

                }

                .slick-next {
                    z-index: 11;
                    width: 50px;
                    height: 50px;
                    top: 50%;
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 8px;
                    border-radius: 50%;
                    transition: 0.3s;

                    &:focus {
                        background: #F5F6FA;

                    }

                    &::before {
                        opacity: .8 !important;
                        content: url(../img/right.svg);
                    }

                    &:hover {
                        background: #1861C6;

                        &::before {
                            opacity: 1 !important;
                            -webkit-filter: brightness(0) invert(1);
                            filter: brightness(0) invert(1);

                        }
                    }
                }

                .slider_wrap * {
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                }

                .slick-slide {
                    margin: 0 10px;
                }

                .slider-item img {
                    max-width: 100%;
                }
            }
        }

        .shop-list {

            margin: 35px 0 35px;

            @include r(1024) {
                margin: 20px 0 20px;
            }

            @include r(576) {
                margin: 10px 0 20px;
            }

            a {
                text-decoration: none;
            }
            .search-content-products{
                .product{
                    .shops-banner{
                        margin: 30px 0;
                        img{
                            display: none;
                        }
                    }
                }
                .active{
                    .d-none-1024{
                        @include r(1024){
                            display: none;
                        }
                        @include r(768){
                            display: block;
                        }
                    }
                    .shops-banner{
                        img{
                            display: block;
                            max-width: 1432px;
                            @include r(1024){
                                max-width: 888px;
                            }
                            @include r(768){
                                max-width: 650px;
                            }
                            @include r(576){
                                max-width: 468px;
                                height: 130px;                           
                            }
                            @include r(479){
                                max-width: 405px !important;
                                height: 130px;                       
                            }
                            @include r(420){
                                max-width: 348px !important;
                                height: 140px;                
                            }
                            @include r(387){
                                max-width: 290px !important;
                                height: 140px;            
                            }
                        }
                    }
                }
            }

            .block-title {
                .rectengle {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 0 10px;
                    @include r(480) {
                        margin-top: 16px;
                    }

                    .rectengle-item {
                        height: 40px;
                        width: 40px;
                        margin-left: 8px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        transition: 0.3s;

                        &:first-child {
                            border: 1px solid #e0e0e0;
                            border-radius: 4px;
                        }

                        &:last-child {
                            border: 1px solid #e0e0e0;
                            border-radius: 4px;
                            margin-left: -1px;
                        }

                        .square-flex {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 25px;
                            &:first-child {
                                margin-bottom: 5px;
                            }

                            
                            .square {
                                width: 5px;
                                height: 5px;
                                border-radius: 2px;
                                background: #d9d9d9;
                                margin-right: 2px;
                                transition: 0.3s;
                            }
                            
                            &:last-child {
                                .line {
                                    width: 12px;
                                }
                            }
                            .line {
                                width: 15px;
                                height: 3px;
                                background: #d9d9d9;
                                border-radius: 9px;
                                transition: 0.3s;
                            }
                        }

                        .square-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .square {
                                width: 6px;
                                height: 6px;
                                background: #1861C6;
                                border-radius: 1px;
                                margin: 2px;
                                transition: 0.3s;
                            }

                            .line {
                                width: 18px;
                                height: 3px;
                                background: #d9d9d9;
                                border-radius: 2px;
                                transition: 0.3s;
                            }
                        }

                        &:hover,
                        &.active {
                            border: 1px solid #007fda;
                            background: #1861C6;

                            .square {
                                background: #fff;

                            }

                            .line {
                                background: #fff;
                                width: 15px;
                                height: 3px;
                            }

                            &:first-child {
                                z-index: 11;
                            }
                        }
                    }
                }
            }

            .shops-product {
                margin-bottom: 30px;
                &.active{
                   
                    .shops-card {
                        display: block;
                        transition: 0.3s ease all;
                    }
                    .shops-card-img{
                        display: grid !important;
                        grid-template-columns: 65% 35%;
                        box-sizing: border-box;
                        gap: 8px !important;
                        margin-top: 16px;
                        .img-100{
                            position: relative;
                            &:first-child{
                                display: flex !important;
                                grid-row: 1/4;
                                height: 208px !important;
                                width: auto;
                                position: relative;
                            }
                            .price {
                                position: absolute;
                                bottom: 8px;
                                left: 8px;
                                background: #FFBF3C;
                                border-radius: 4px;
                                font-family: ProductSans-Medium;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 4px 6px;
                                color: #000000;
                                display: block !important;
                                transition: 0.3s ease all;
                            }
                            .light{
                                background: rgba(0, 0, 0, 0.1) !important;
                                border-radius: 4px;
                            }
                        }
                    }
                    .shops-card-count{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                   
                    
                    .shops-card{
                            &:hover{
                            background: #1861C6;
                            .shops-card-img{
                                .img-100{
                                    .price{
                                        display: none !important;
                                    }
                                    .light{
                                        display: none !important;
                                    }
                                }
    
                            }
                            .card-title{
                                h4{
                                    color: #fff !important;
                                }
                                p{
                                    color: #A3C0E8 !important;
                                }
                                span{
                                    color: #FFF !important;
                                }
                            }
                            .shops-card-count{
                                p{
                                    color: #FFF !important;
                                }
                                h4{
                                    color: #FFBF3C !important;
                                }
                            }
                        }
                    }
                }
            }                .shops-card {
                    background: #F5F6FA;
                    border-radius: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    width: 100%;
                    @include r(1024){   
                    flex-wrap: wrap;
                    }
                    @include r(420){
                        display: block;
                    }
                    .shops-card-title {
                        .shop-logo {
                            min-width: 60px;
                            width: 60px;
                            height: 60px;
                            background: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            margin-right: 15px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                @include r(420){
                                    position: relative;
                                }
                            }
                        }

                        .card-title {
                            margin: 0;
                            line-height: 16px !important;
                            h4 {
                                color: #000000;
                                font-size: 18px;
                                line-height: 22px;
                                font-family: 'ProductSans-Medium';
                                margin-bottom: 4px;
                            }

                            p {
                                color: #7A7D85;
                                font-size: 12px;
                                line-height: 15px;

                                font-family: 'ProductSans';
                            }

                            .reyting {
                                color: #000000;
                                font-size: 12px;
                                line-height: 15px;
                                font-family: 'ProductSans';

                                img {
                                    width: 12px;
                                    height: 12px;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    .shops-card-count {
                        order: 2;
                        @include r(1024){
                            order: 3;
                            @include r(992){
                                order: 2;
                                @include r(576){
                                    order: 3;
                                    @include r(420){
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                    }
                                }
                            }
                        }
                        
                        p {
                            color: #13369E;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: ProductSans-Medium;
                        }

                        h4 {
                            color: #7A7D85;
                            font-size: 38px;
                            line-height: 46px;
                            font-family: 'ProductSans';
                            margin-bottom: 0;
                        }
                    }
                    .shops-card-img {
                      display: flex;
                      gap: 0 10px;
                      order: 3;
                      @include r(1024){
                        margin-top: 16px;
                        order: 2;
                        @include r(992){
                            order: 3;
                            @include r(576){
                                order: 2;
                                @include r(420){
                                    display: grid !important;
                                    grid-template-columns: 65% 35%;
                                    box-sizing: border-box;
                                    gap: 8px !important;
                                    margin-top: 16px;
                                }
                            }
                        }
                      }
                        .img-100 {
                            background: #FFFFFF;
                            border-radius: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100px;
                            width: 100px;
                            position: relative;
                            
                            img {
                                width: 80%;
                                height: 80%;
                                object-fit: contain;
                            }
    
                            .price {
                                display: none;
                                @include r(420){
                                    position: absolute;
                                bottom: 8px;
                                left: 8px;
                                background: #FFBF3C;
                                border-radius: 4px;
                                font-family: ProductSans-Medium;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 4px 6px;
                                color: #000000;
                                display: block !important;
                                transition: 0.3s ease all;
                                }
                            }
                            .light{
                                @include r(420){
                                background: rgba(0, 0, 0, 0.1) !important;
                                }
                            }
    
                        }
                      
                        .didn-stay{
                            background: #FFFFFF;
                            border-radius: 4px;
                            margin: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 95px;
                            position: relative;
                            .price{
                                position: absolute;
                                bottom: 8px;
                                left: 8px;
                                
                                font-family: ProductSans-Medium;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 4px 6px;
                                color: #000000;
                               
                                transition: 0.3s ease all;
                                background: rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                            }
                        }
    
                        .img-100:first-child{                           
                            display: none;
                            @include r(420){
                                display: block;
                                display: flex !important;
                                grid-row: 1/4;
                                height: 208px !important;
                                width: auto;
                            }
                        }
                       
                    }
                }
            }
            .shops-main-banner{
                margin-bottom: 30px;
                @include r(1024){
                    width: 100%;
                    overflow: hidden;
                    border-radius: 12px;
                    height: 220px;
                }
            }
            .shop-item {
                display: none;
                background: #F5F6FA;
                border-radius: 8px;
                padding: 20px;
                transition: 0.3s ease all;
                margin: 15px 0;
                user-select: none;

                .shop-info {
                    display: flex;
                    gap: 16px;
                    margin-bottom: 20px;

                    .shop-about {
                        max-width: 70%;

                        .shop-title {
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            margin-bottom: 2px;
                            line-height: 22px;
                            color: #000000;
                            transition: 0.3s ease all;
                        }

                        .shop-desc {
                            font-family: ProductSans;
                            font-size: 12px;
                            line-height: 15px;
                            color: #7A7D85;
                            margin-bottom: 3px;
                            transition: 0.3s ease all;
                        }

                        .shop-lavel {
                            font-family: ProductSans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #000000;
                            display: flex;
                            gap: 6px;
                            align-items: center;
                            transition: 0.3s ease all;
                            .icon{
                                margin: 0;
                            }
                        }

                    }
                }

                .shop-photo {
                    background: #fff;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .shop-galerey {
                    display: grid;
                    grid-template-columns: 65% 35%;
                    box-sizing: border-box;

                    .galerey-item {
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 95px;
                        position: relative;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }

                        .price {
                            position: absolute;
                            bottom: 8px;
                            left: 8px;
                            background: #FFBF3C;
                            border-radius: 4px;
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            padding: 4px 6px;
                            color: #000000;
                            display: none;
                            transition: 0.3s ease all;
                        }
                       


                    }
                    .didn-stay{
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 95px;
                        position: relative;
                        .price{
                            position: absolute;
                            bottom: 8px;
                            left: 8px;
                            
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            padding: 4px 6px;
                            color: #000000;
                           
                            transition: 0.3s ease all;
                            background: rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                        }
                    }

                    .galerey-item:first-child() {
                        grid-row: 1/4;
                        height: 200px !important;
                    }
                }
                .shop-gallery-footer{
                    display: flex;
                    justify-content: space-between;
                    span{
                        margin-top: 26px;
                        color: #13369E;font-size: 12px;
                        line-height: 15px;
                        font-family: 'ProductSans';
                    }
                    h4{
                        color: #7A7D85; font-size: 48px;
                        line-height: 58px;
                        font-family: 'ProductSans';
                    }
                }

                &:hover {
                    background: #1861C6;

                    .shop-info {
                        .shop-about {

                            .shop-itle,
                            .shop-desc,
                            .shop-lavel {
                                color: #fff;
                            }

                            .shop-desc {
                                opacity: 0.8;
                            }
                        }
                    }

                    .shop-galerey {
                        .galerey-item {
                            .price {
                                display: none;
                            }
                        }
                        .didn-stay{
                            .price{
                                display: none;
                            }
                        }

                    }
                    .shop-gallery-footer{
                        span{
                            color: #FFFFFF;
                        }
                        h4{                            
                            color: #FFBF3C;
                        }
                    }
                }

            }
            .active{

                .shop-item{
                    display: block;
                }
            }


            .active {
               
                border-radius: 8px;
                
                transition: 0.3s ease all;
                
                user-select: none;

                .shop-info {
                    display: flex;
                    gap: 16px;
                    margin-bottom: 20px;

                    .shop-about {
                        max-width: 70%;

                        .shop-title {
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            margin-bottom: 2px;
                            line-height: 22px;
                            color: #000000;
                            transition: 0.3s ease all;
                        }

                        .shop-desc {
                            font-family: ProductSans;
                            font-size: 12px;
                            line-height: 15px;
                            color: #7A7D85;
                            margin-bottom: 3px;
                            transition: 0.3s ease all;
                        }

                        .shop-lavel {
                            font-family: ProductSans;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            color: #000000;
                            display: flex;
                            gap: 6px;
                            align-items: center;
                            transition: 0.3s ease all;
                        }

                    }
                }

                .shop-photo {
                    background: #fff;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .shop-galerey {
                    display: grid;
                    grid-template-columns: 65% 35%;
                    box-sizing: border-box;

                    .galerey-item {
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 95px;
                        position: relative;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }

                        .price {
                            position: absolute;
                            bottom: 8px;
                            left: 8px;
                            background: #FFBF3C;
                            border-radius: 4px;
                            font-family: ProductSans-Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            padding: 4px 6px;
                            color: #000000;
                            display: inline-block;
                            transition: 0.3s ease all;
                        }


                    }

                    .galerey-item:first-child() {
                        grid-row: 1/4;
                        height: 200px !important;
                    }
                }

                // &:hover {
                

                //     .shop-info {
                //         .shop-about {

                //             .shop-itle,
                //             .shop-desc,
                //             .shop-lavel {
                //                 color: #fff;
                //             }

                //             .shop-desc {
                //                 opacity: 0.8;
                //             }
                //         }
                //     }

                //     .shop-galerey {
                //         .galerey-item {
                //             .price {
                //                 display: none;
                //             }
                //         }

                //     }
                // }

            }

        }

        .shop-main-banner {

            border-radius: 8px;

            .shop-info-card {
                background: #FFFFFF;
                border: 1px solid #D7DDEB;
                border-radius: 4px;
                padding: 24px;
                display: inline-block;
                min-width: 446px;

                @include r(576) {
                    min-width: 0 !important;

                }

                .shop-info-card-content {
                    display: flex;
                    gap: 16px;
                    margin-bottom: 24px;

                    @include r(576) {
                        flex-wrap: wrap;

                    }

                    .shop-photo {
                        background: #C4C4C4;
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }

                    .shop-about {
                        max-width: 70%;

                        @include r(576) {
                            max-width: 100%;

                        }

                        .shop-info-desc {
                            font-family: ProductSans;
                            font-size: 16px;
                            line-height: 18px;
                            color: #7A7D85;
                            margin-bottom: 0;
                        }

                        .shop-info-title {
                            font-family: ProductSans-Bold;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 35px;
                            line-height: 36px;
                            margin-bottom: 0;
                            color: #000000;

                        }

                        .icon {

                            color: #000000;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: 'ProductSans';

                            img {
                                width: 12px;
                                height: 12px;

                                &:first-child {
                                    margin-left: 7px;
                                }
                            }

                        }
                    }

                }

                .uslugi {
                    display: flex;
                    gap: 24px;

                    @include r(576) {
                        flex-wrap: wrap;
                    }

                    .shop-lavel {
                        font-family: ProductSans;
                        font-size: 14px;
                        line-height: 14px;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .icon {
                            color: #7A7D85;
                            font-size: 48px;
                            line-height: 58px;
                            font-family: 'ProductSans';
                            margin-left: 0px;
                        }

                        .icon-text {
                            color: #13369E;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: 'ProductSans';
                            padding-bottom: 3px;
                        }

                        .lavel-text {
                            line-height: 14px;
                            display: flex;
                            align-items: center;
                            color: #13369E;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: 'ProductSans';
                        }
                    }

                    .shop-time {
                        font-family: ProductSans;
                        font-size: 14px;
                        line-height: 14px;
                        color: #000000;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        margin: 16px;

                        .icon {
                            color: #7A7D85;
                            font-size: 48px;
                            line-height: 58px;
                            font-family: 'ProductSans';
                            margin-left: 20px;
                        }

                        .icon-text {
                            color: #13369E;
                            font-size: 12px;
                            line-height: 15px;
                            font-family: 'ProductSans';
                            padding-bottom: 3px;
                        }

                        .time-text {
                            line-height: 14px;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .main-banner-right {
            img {
                margin-left: 24px;
                height: 229px;
                max-width: 993px;
                @include r(1024){
                    margin-left: 13px;
                    height: 229px;
                    max-width: 498px;
                }
                @include r(768){
                    display: none;
                }
            }
        }

        .shop-menu-list {
            margin-top: 32px;
            display: flex;
            gap: 12px;
            align-items: center;
            flex-wrap: wrap;

            @include r(1024) {
                margin-top: 30px;
            }
            @include r(768){
                display: flex;
                justify-content: center;
            }
            @include r(576) {
                margin-top: 20px;
            }
                            

            .shop-menu-link {
                display: inline-flex;
                align-items: center;
                gap: 12px;
                padding: 12px 28px;
                background: #FFFFFF;
                border: 1px solid #D7DDEB;
                box-sizing: border-box;
                border-radius: 4px;
                text-decoration: none;
                font-family: ProductSans;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;

                img {
                    filter: grayscale(100%) saturate(0%) opacity(60%) contrast(93%);
                    -webkit-filter: grayscale(100%) saturate(0%) opacity(60%) contrast(93%);
                    -moz-filter: grayscale(100%) saturate(0%) opacity(60%) contrast(93%);
                }

                &.active {
                    color: #1861C6;
                    background: #FFFFFF;
                    border: 1px solid #1861C6;
                    border-radius: 4px;

                    .menu-img {
                        img {
                            filter: invert(26%) sepia(82%) saturate(2150%) hue-rotate(205deg) brightness(85%) contrast(87%);
                        }
                    }
                }
            }
        }

        .shop-menu-category {
            .menu-category-title {
                h4 {
                    padding-top: 50px;
                    color: #000000;
                    font-size: 35px;
                    line-height: 42px;
                    font-family: 'ProductSans-Bold';
                    margin-bottom: 24px;
                }
            }

            a {
                margin-right: 20px;
                background: #F5F6FA;
                border-radius: 8px;
                padding: 14px 15px;
                text-decoration: none;
                color: #13369E;
                font-size: 14px;
                line-height: 17px;
                font-family: 'ProductSans';
                @include r(387){
                    margin-top: 10px;
                    display: flex;
                    width: 50%;
                
                }

                img {
                    max-width: 42px;
                    height: 32.92px;
                    margin-right: 10px;
                }

                span {
                    margin-left: 10px;
                    color: #7A7D85;
                    font-size: 12px;
                    line-height: 15px;
                    font-family: 'ProductSans';
                }
            }
        }

        .shop-product-slider-block {
            margin: 60px 0;

            @include r(1200) {
                margin: 40px 0;

                @include r(1024) {
                    margin: 30px 0;

                    @include r(576) {
                        margin: 20px 0;

                    }
                }

            }

            .for-slider {
                .slick-prev {
                    left: 10px;
                }

                .slick-next {
                    right: 10px;
                }
            }
        }

        .shop-banner-item {
            height: 300px;
            width: 100%;
            background: #C4C4C4;
            border-radius: 8px;
            overflow: hidden;

            @include r(992) {
                height: 220px;

                @include r(768) {
                    height: 180px;

                    @include r(576) {
                        height: 130px;

                    }
                }

            }

            img {
                width: 100%;
                height: 100%;

            }
        }


        .for-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 60px;
            margin-bottom: 30px;

            @include r(1200) {
                margin: 40px 0 20px;

                @include r(1024) {
                    margin: 30px 0 10px;

                    @include r(576) {
                        margin: 20px 0 10px;

                    }
                }

            }

            .more-button {
                display: inline-flex;
                padding: 17px 34px;
                background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
                box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
                border-radius: 8px;
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                text-decoration: none;
            }
        }

        .page-content-list {
            margin-top: 60px;

            @include r(1024) {
                margin-top: 30px;

                @include r(576) {
                    margin-top: 20px;

                }

            }
        }

        .shop-products {
            .filter-button-list {
                .input-item {
                    .nice-select {
                        min-width: 343px;
                        height: 56px;
                        background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 8px;

                        @include r(576) {
                            min-width: 303px;

                        }

                        &::after {
                            width: 8px;
                            height: 8px;
                            border-bottom: 1.5px solid #A9ADB8;
                            border-right: 1.5px solid #A9ADB8;
                        }

                        .current {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            align-items: center;
                            font-family: ProductSans;
                            font-size: 18px;
                            line-height: 26px;
                            color: #000000;
                        }

                        .list {
                            width: 100%;

                            .option {
                                padding: 7px 18px;
                                font-family: ProductSans;
                                font-size: 18px;
                                line-height: 26px;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }

        .shop-info-list {
            margin-top: 60px;
            margin-bottom: 30px;

            @include r(1024) {
                margin-top: 30px;

                @include r(992) {
                    margin-top: 10px;
                    margin-bottom: 20px;

                    @include r(576) {
                        margin-top: 0px;
                        margin-bottom: 10px;
                    }
                }

            }

            .shop-info-item {
                .shop-info-item-title {
                    font-family: ProductSans-Bold;
                    font-size: 25px;
                    line-height: 30px;
                    color: #13369E;

                    @include r(1200) {
                        font-size: 22px;
                        line-height: 24px;
                        margin-bottom: 0;

                        @include r(992) {
                            margin-top: 20px;
                        }
                    }
                }

                .mini-info-block {
                    margin-top: 24px;

                    @include r(1200) {
                        margin-top: 16px;
                    }

                    .title {
                        font-family: ProductSans;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 19px;
                        margin-bottom: 4px;
                        color: #7A7D85;
                    }

                    .text {
                        font-family: ProductSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        color: #000000;

                        a {
                            color: #000000;
                            text-decoration: none;
                        }
                    }

                    .title-danger {
                        font-family: "ProductSans-Medium";
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 19px;
                        margin-bottom: 4px;
                        color: #13369E;

                        img {
                            margin-right: 8px;
                        }
                    }

                    .title-light {
                        margin-left: 26px;
                        font-family: ProductSans;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 19px;
                        margin-bottom: 4px;
                        color: #7A7D85;
                    }

                    .list {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;

                        .adress-item {
                            display: flex;
                            gap: 12px;
                            align-items: flex-start;
                            margin-bottom: 8px;

                            &:last-child() {
                                margin-bottom: 0;
                            }

                            .icon {
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }

    
}