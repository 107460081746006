.container-fluid{
    padding: 0 225px;
}  
.section-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    h2{
        font-family: ProductSans-Bold;
        font-size: 35px;
        line-height: 42px;
        color: #000;
    }
    a{
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        font-family: ProductSans-Regular;
        color: #1861C6;
        img{
            margin-left: 12px;
        }
    }
}
.font-medium{
    font-family: ProductSans-Medium;
    font-size: 18px;
    line-height: 22px;
    color: #000;
    @include r(992) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
            
    }
}
.font-regular{
    font-family: ProductSans-Regular;
    font-size: 16px;
    line-height: 19px;
    color: #7A7D85;
    @include r(992) {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
        @include r(420) {
           
        }
    }
}
.article-cards{
    margin-bottom: 32px;
    @include r(1024) {
        margin-bottom: 24px;
        @include r(992) {
           margin-bottom: 16px;
        }
    }
    .card-top{
        position: relative;
        .card-img{
            margin-bottom: 16px;
            img{
                width: 100%;
                height: 230px;
                border-radius: 4px;
            }
        }
        .date{
            position: absolute;
            bottom: 14px;
            left: 14px;
            color: #fff; 
            font-family: ProductSans-Medium;
            background: #1861C6;
            border-radius: 4px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            span{
                font-size: 12px;
            }
            .calendar{
                margin-right: 6px;
            }
        }
    }

}