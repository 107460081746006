@font-face {
  font-family: ProductSans-Black;
  src: url(../fonts/ProductSans-Black.woff2) format("woff2"), url(../fonts/ProductSans-Black.woff) format("woff"), url(../fonts/ProductSans-Black.ttf) format("truetype");
  font-style: normal;
}

@font-face {
  font-family: ProductSans-BlackItalic;
  src: url(../fonts/ProductSans-BlackItalic.woff2) format("woff2"), url('../fonts/ProductSans-BlackItalic.woff') format("woff"), url('../fonts/ProductSans-BlackItalic.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: ProductSans-Bold;
  src: url(../fonts/ProductSans-Bold.woff2) format("woff2"), url('../fonts/ProductSans-Bold.woff') format("woff"), url('../fonts/ProductSans-Bold.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: ProductSans-Italic;
  src: url('../fonts/ProductSans-Italic.woff2') format("woff2"), url('../fonts/ProductSans-Italic.woff') format("woff"), url('../fonts/ProductSans-Italic.ttf') format("truetype");
  font-style: normal;
}

@font-face {
  font-family: ProductSans-BoldItalic;
  src: url('../fonts/ProductSans-BoldItalic.woff2') format("woff2"), url('../fonts/ProductSans-BoldItalic.woff') format("woff"), url('../fonts/ProductSans-BoldItalic.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-Light;
  src: url('../fonts/ProductSans-Light.woff2') format("woff2"), url('../fonts/ProductSans-Light.woff') format("woff"), url('../fonts/ProductSans-Light.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-LightItalic;
  src: url('../fonts/ProductSans-LightItalic.woff2') format("woff2"), url('../fonts/ProductSans-LightItalic.woff') format("woff"), url('../fonts/ProductSans-LightItalic.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-Medium;
  src: url('../fonts/ProductSans-Medium.woff2') format("woff2"), url('../fonts/ProductSans-Medium.woff') format("woff"), url('../fonts/ProductSans-Medium.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-MediumItalic;
  src: url('../fonts/ProductSans-MediumItalic.woff2') format("woff2"), url('../fonts/ProductSans-MediumItalic.woff') format("woff"), url('../fonts/ProductSans-MediumItalic.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-Regular;
  src: url('../fonts/ProductSans-Regular.woff2') format("woff2"), url('../fonts/ProductSans-Regular.woff') format("woff"), url('../fonts/ProductSans-Regular.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans;
  src: url('../fonts/ProductSans-Regular.woff2') format("woff2"), url('../fonts/ProductSans-Regular.woff') format("woff"), url('../fonts/ProductSans-Regular.ttf') format("truetype");
  font-style: normal;
}
@font-face {
  font-family: ProductSans-Thin;
  src: url('../fonts/ProductSans-Thin.woff2') format("woff2"), url('../fonts/ProductSans-Thin.woff') format("woff"), url('../fonts/ProductSans-Thin.ttf') format("truetype");
  font-style: normal;
}