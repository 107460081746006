@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "blocks/variables.scss";
@import "blocks/mixins.scss";
@import "blocks/normalize.scss";
@import "blocks/sprite-png.scss";
@import "blocks/nice.scss";
@import "blocks/fonts.scss";
@import "blocks/font/fontawesome.scss";
@import "blocks/slick.scss";
@import "blocks/slick-theme.scss";
@import "blocks/sina-nav.scss";
// PAGE COMPONENTS
@import "blocks/header.scss";
@import "blocks/footer.scss";
@import "blocks/search.scss";
@import "blocks/shops.scss";
@import "blocks/owl/owl.carousel.scss";
@import "blocks/owl/owl.theme.default.scss";
@import "blocks/index.scss";
@import "blocks/products.scss";
@import "blocks/catalog.scss";
@import "blocks/profile.scss";
@import "blocks/payment.scss";
@import "blocks/partner.scss";
@import "blocks/component.scss";
@import "blocks/comparison.scss";
@import "blocks/about.scss";

.icon {
	display: inline-block;
}
.icon-chrome {
	@include sprite($chrome);
}
.icon-placeholder {
	@include sprite($placeholder);
}
.container{
	@include r(1400) {
		max-width: 78.5% ;
	}
  }
a:hover{
	text-decoration: none;
}
a{
	text-decoration: none;
}
h2{
	margin-bottom: 0;
}
p{
	margin-bottom: 0;
}
.page-padding{
	padding: 32px 0;
}
.app-button{
	width: 100%;
	height: 56px;
	background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
	box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
	border-radius: 8px;
	font-family: ProductSans-Medium;
	font-style: normal;
	display: flex;align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #FFFFFF;
	
	&:hover{
		color: #fff;
	}
	&:focus{
		box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
		outline: none;
	}
	&.yellow{
		background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
		box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
		border-radius: 8px;
		color: #000000;
		&:focus{
			box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
			outline: none;
		}
	}
}
.two-app-button{
	padding: 17px 34px;
	border: none;
	background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
	box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
	border-radius: 8px;
	font-family: ProductSans-Medium;
	font-style: normal;
	display: inline-flex;
	gap: 16px;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #FFFFFF;
	text-decoration: none;
	@include r(768) {
		padding: 14px 24px;   
		font-size: 16px;      
		line-height: 18px;
		gap: 8px;
	}
	&:hover{
		color: #fff;
	}
	&:focus{
		box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);
		outline: none;
	}
	&.yellow{
		background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
		box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
		color: #000000;
		&:focus{
			box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
			outline: none;
		}
	}
	&.orang{
		background: #FC3F1D;
		box-shadow: 0px 10px 15px -5px rgba(252, 63, 29, 0.5) ;

		&:focus{
			box-shadow: 0px 10px 15px -5px rgba(252, 63, 29, 0.5) ;
			outline: none;
		}
	}
}


.icon-heart{
	border:  transparent;
}


.show_all{
	font-family: 'ProductSans';
    padding: 12px 23px;
    background: #EBF4FF;
    border-radius: 5px;
		color: #1861C6;
		font-size: 12px;
		text-decoration: none;
		display: inline-block;
		
		span{
			font-size: 16px;
		}
}


.favorite-item{
	.product-photo{
		height: 285px;
		margin: 0 !important;
		background: transparent;
		align-items: flex-end !important;
		transition: all 0.3s;
		&:hover{
			.favorite-photo{
				width: 100%;
				height: 100%;
				border: transparent;
			}
		}
		&.border-img{
			border: 0;
			border-color: unset;
		}
		.favorite-photo{
			width: 250px;
			height: 250px;
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		transition: all 0.3s;
		border: 1px solid #D7DDEB;
		border-radius: 4px;
			z-index: 99;
		}
		.icon-heart{
			background: none;
			z-index: 999;
			.heart-default{
				display: block !important;
			}
			.heart-active{
				display: none;
			}
			&.active{
				.heart-default{
					display: none !important;
				}
				.heart-active{
					display: block;
				}
			}
		}
		img{
			width: 108px;
			height: 208px;
			object-fit: contain;
		}
	}
}