.payment-page-content{
    .title{
        font-family: ProductSans;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #13369E;
        margin-bottom: 0;
    }
    .order-right-card{
        background: #F5F6FA;
        border-radius: 8px;
        padding: 30px;
        .order-number{
            font-family: ProductSans;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            color: #000000;
        }
        .order-info{
            .table-list{
                gap: 10px;
                margin-bottom: 16px;
                .price{
                    white-space: nowrap;
                    padding-left: 16px;
                }
                th,td{
                    border: none;
                    padding: 0;
                    vertical-align: top;
                    padding: 8px 0;
                    span{
                        font-family: ProductSans;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                    }
                }
                tbody{
                    border: none;
                    td{
                        border: none;
                    }
                }
                
                    tfoot{
                    th span{
                        font-size: 18px;
                    }
                }
            }
            .for-pay-system{
                margin-bottom: 35px;
                .inputGroup{
                    position: relative;
                        .pay-over{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 16px;
                            cursor: pointer;
                            color: white;
                            user-select: none;
                            border: 1px solid #A9ADB8;
                            border-radius: 8px;
                            margin-top: 16px;
                            img{
                                max-height: 26px;
                            }
                        }                       
                       input[type="radio"] {
                         visibility: hidden;
                         height: 0; 
                         width: 0; 
                         position: absolute;
                       }
                       input[type="radio"]:checked + label {
                        background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
                        border-color: #1861C6;
                       }
                       input[type="radio"]:checked + label img{
                        filter: brightness(0) invert(1);
                       }
                       input[type="radio"]:hover:not(:checked) + label {
                        background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
                        color: var(--color-black);
                       }
                       input[type="radio"]:hover:not(:checked) + label img {
                        filter: brightness(0) invert(1);
                       }
                }
            }
            .form-item{
                margin-bottom: 32px;
                .link{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #1861C6;
                    text-decoration: none;
                    margin-left: 5px;
                    border-bottom: 1px dashed #1861C6;
                }
                .styled-checkbox {
                    position: absolute; 
                    opacity: 0; 
                  
                    & + label {
                        position: relative;
                        cursor: pointer;
                        padding: 0;
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                    }
                  
                    & + label:before {
                      content: '';
                      margin-right: 10px;
                      display: inline-block;
                      vertical-align: text-top;
                      width: 17px;
                      height: 16px;
                      background: white;
                      background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 4px;

                    }
                    &:hover + label:before {
                        background: #FFFFFF;
                    }
                    
                    &:checked + label:before {
                        background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 4px;
                    }
                    
                    &:disabled + label {
                      color: #b8b8b8;
                      cursor: auto;
                    }
                  
                    &:disabled + label:before {
                      box-shadow: none;
                      background: #ddd;
                    }
                  
                    // Checkmark. Could be replaced with an image
                    &:checked + label:after {
                      content: '';
                      position: absolute;
                      left: 4px;
                      top: 8px;
                      background: #1861C6;
                      width: 2px;
                      height: 2px;
                      box-shadow: 
                        2px 0 0 #1861C6,
                        4px 0 0 #1861C6,
                        4px -2px 0 #1861C6,
                        4px -4px 0 #1861C6,
                        4px -6px 0 #1861C6,
                        4px -8px 0 #1861C6;
                      transform: rotate(45deg);
                    }
                  }
            }
        }
    }
    .order-form-card{
        background: #F5F6FA;
        border-radius: 8px;
        padding: 30px;
        .order-nav{
            display: flex;
            align-items: center;
            padding: 8px;
            background: #E8E9ED;
            border-radius: 12px;
            width: 100%;
            gap: 8px;
            margin-bottom: 32px;
            .order-nav-link{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                text-decoration: none;
                padding: 13px;
                background: #FFFFFF;
                color: #A9ADB8;
                border-radius: 8px;
                &.active{
                    background: #FFBF3C;
                    color: #000000;
                }
                span{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;   
                    @include r(576) {
                        font-size: 16px;   
                        line-height: 20px;      
                    }
                }
            }
        }
        .title{
            margin-bottom: 16px;
        }
        .order-form-content{
            .profil-input{
                background: #FFFFFF;
                border: 1px solid #D7DDEB;
                box-sizing: border-box;
                border-radius: 8px;
                width: 100%;
                height: 55px;
                font-family: ProductSans-Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 23px;
                color: #000;
                margin-bottom: 0;
                &::placeholder{
                    color: #A9ADB8;
                }
            }
            .label{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;     
                margin-bottom: 8px;  
            }
            .input-block{
                margin-bottom: 32px;
                .input-group{
                    display: flex;
                    box-sizing: border-box;
                    width: 85%;
                    gap: 16px;
                    .input-item{
                        width: 48%;
                        box-sizing: border-box;
                        .input{
                            position: relative;
                            width: 100%;
                            &::after{
                                display: table;
                                content: "";
                                clear: both;
                            }
                            .icon{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                font-size: 18px;
                                color: #A9ADB8;
                                z-index: 50;
                                left: 16px;
                                width: 20px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img{
                                    max-width: 100%;
                                }
                            }
                            .profil-input{
                                padding-left: 42px;
                                box-shadow: none;
                                outline: none;
                                &.address{
                                    padding: 16px 18px;
                                }
                                &.not-icon{
                                    padding: 16px 20px;
                                }
                            }
                        }
                    }
                    @include r(768) {
                        width: 100%;  
                        .input-item{
                            width: 100%;
                            .profil-input{
                                font-size: 16px;
                            }
                        }          
                    }
                    
                }
                .nice-select{
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 8px;
                    width: 100%;
                    height: 55px;
                    font-family: ProductSans-Regular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 23px;
                    color: #000;
                    margin-bottom: 0;
                    &:after{
                        width: 9px;
                        height: 9px;
                    }
                    .current{
                        display: flex;height: 100%;
                        align-items: center;
                        width: 100%;
                        color: #A9ADB8;
                    }
                    .list{
                        width: 100%;
                        .option{
                            padding: 3px 20px;
                            font-size: 14px;
                            &:hover{
                                color: #0133EC;
                                background-color: #fff;
                            }
                            &.selected{
                                background-color: #fff;
                                color: #A3A3A3;
                                font-weight: normal;
                            }
                        }
                    }
                }

            }
            .radio-list{
                .form-item{
                    margin-bottom: 8px;
                    [type="radio"]:checked,
                    [type="radio"]:not(:checked) {
                        position: absolute;
                        left: -9999px;
                    }
                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label
                    {
                        position: relative;
                        padding-left: 22px;
                        cursor: pointer;
                        display: inline-block;
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        color: #000000;

                    }
                    [type="radio"]:checked + label:before,
                    [type="radio"]:not(:checked) + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #ddd;
                        border-radius: 100%;
                        background: #fff;
                    }
                    [type="radio"]:checked + label:after,
                    [type="radio"]:not(:checked) + label:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #1861C6;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 100%;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }
                    [type="radio"]:not(:checked) + label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    [type="radio"]:checked + label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
        }
        .instalment-list{
            .instalment-item{
                margin-bottom: 24px;
                input{
                    display: none;
                }
                .instalment-info{
                    border: 1px solid #A9ADB8;
                    box-sizing: border-box;
                    border-radius: 8px;
                    display: block;
                    padding: 16px;
                    color: #000000;
                    
                    .instalment-mounth{
                        font-family: ProductSans-Bold;
                        font-style: normal;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 8px;
                    }
                    .instalment-price{
                        font-family: ProductSans;
                        font-size: 16px;
                        line-height: 19px;

                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
                input[type="radio"]:checked + label {
                    background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
                    border-color: #1861C6;
                    color: #FFFFFF;
                   }
            }
        }
    }
    
}
.for-back-button{
    margin-top: 50px;
    @include r(992) {
    margin-top: 30px;
           
    }
    .back{
        background: #FC3F1D;
        box-shadow: 0px 10px 15px -5px rgba(252, 63, 29, 0.5);
        border-radius: 8px;
        border: none;
        padding: 17px 34px;
        display: flex;
        gap: 14px;
        font-family: ProductSans-Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;

    }       
}
.upload-list{
    display: flex;
    gap: 24px;flex-wrap: wrap;
    .avatar-upload {
        position: relative;
        max-width: 205px;
        .avatar-edit {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            input {
                display: none;
                + label {
                    width: 70px;
                    height: 70px;
                    margin-bottom: 0;
                    border-radius: 12px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    background: #ffffffd7;
                    border: 1px solid transparent;
                    cursor: pointer;
                    font-weight: normal;
                    transition: all .2s ease-in-out;
                    img{
                        max-width: 100%;
                    }
                 
                    &:after {
                        content: "";
                        font-family: 'FontAwesome';
                        color: #757575;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        right: 0;
                        text-align: center;
                        margin: auto;
                    }
                }
            }
        }
        .avatar-preview {
            width: 150px;
            height: 150px;
            position: relative;
            background: #FFFFFF;
            border: 1px dashed #D7DDEB;
            border-radius: 12px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            > div {
                width: 100%;
                height: 100%;
                border-radius: 12px;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    .upload-item{
        max-width: 150px;
        .upload-info{
            margin-top: 16px;
            .info-title{
                font-family: ProductSans-Bold;
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 8px;
            }
            .text{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0;
                color: #000000;
            }
        }
    }
}