@mixin clearfix() {
  &::after {
    content: "";

    display: table;
    clear: both;
  }
}
@mixin r($point) {
  @if $point==240 {
    @media (max-width: 240px) {
            @content;
    }
}

@if $point==320 {
    @media (max-width: 320px) {
            @content;
    }
}
@if $point==387 {
@media (max-width: 387px) {
        @content;
}
}
@if $point==420 {
  @media (max-width: 420px) {
          @content;
  }
}

@if $point==479 {
    @media (max-width: 479px) {
            @content;
    }
}

@if $point==576 {
    @media (max-width: 576px) {
            @content;
    }
}

@if $point==768 {
    @media (max-width: 768px) {
            @content;
    }
}

@if $point==992 {
    @media (max-width: 992px) {
            @content;
    }
}

@if $point==1024 {
    @media (max-width: 1024px) {
            @content;
    }
}

@if $point==1200 {
    @media (max-width: 1200px) {
            @content;
    }
}
@if $point==1365 {
    @media (max-width: 1365px) {
            @content;
    }
}

@if $point==1600 {
    @media (max-width: 1600px) {
            @content;
    }
}
  @if $point==1400 {
    @media (min-width: 1400px) {
            @content;
    }
  }
}
//@mixin container() {
////  width: 280px;
//  margin: 0 auto;
//
//  @media (min-width: $screen-md) {
////    width: 620px;
//  }
//
//  @media (min-width: $screen-lg) {
////    width: 940px;
//  }
//}
