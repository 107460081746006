.search-page{
    .search-content-products{
        margin-bottom: 40px;
        .filter-button-list{
            @include r(576){
                display: flex;
                flex-direction: column;
                margin: 15px 0;
                gap: 12px;
            }
        }
    }
    .search-content-list{
        .advertising{
            img{
                min-width: 230px;
            }
        }
        .search-product-item{
            margin:8px 0;
            display: flex;
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            border-radius: 4px;
            @include r(768) {
                flex-wrap: wrap;
            }  
            .icon-heart{
                
                font-size: 22px;
                line-height: 20px;
                color: #BDBDBD;
                &.active{
                    color: #FFBF3C;
                }
            }             
        }
        .search-product-photo{
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            img{
                max-width: 100%;
                // max-height: 100%;
                max-height: 160px;
            }
        }
        .search-product-infos{
           
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 53%;
            padding: 24px 20px 5px;
            img{
                width: 13px;
                height: 13px;
            }
            .product-title{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #000000;

            }
            .product-text{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }
            .shop-info{
                margin-top: 10px;
                .shop-top{
                    display: inline-flex;
                    gap: 6px;
                    align-items: center;
                    margin-bottom: 8px;
                    span{
                        font-family: ProductSans;
                        font-size: 14px;
                        line-height: 17px;
                        color: #7A7D85;
                    }
                    .shop-title{
                        font-family: ProductSans;
                        font-size: 16px;
                        line-height: 19px;
                        color: #1B6CDD;
                        text-decoration: none;
                        @include r(992){
                            font-size: 15px;
                        }
                        img{
                            margin-right: 4px;
                        }
                    }
                }
                .stars-list{
                    line-height: 16px;
                    margin-left: 24px;
                }
            }
            @include r(768) {
                width: 100%;
                padding: 0 20px 0;
            }
        }
        .search-product-buy{
           
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            padding: 24px 0 0;
            padding-right: 20px;
           
            .discount-price-div{
                display: flex;
                gap: 12px;
                align-items: center;
                margin-bottom: 4px;
                p{
                    color: #000000;font-size: 16px;
                    font-family: ProductSans;
                    line-height: 19px;opacity: 0.5;
                }
                .discoun-price{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 0;
                    text-decoration-line: line-through;
                    color: #7A7D85;

                }
                .discount{
                    background: #FC3F1D;
                    border-radius: 4px;
                    padding: 4px 8px;
                    display: flex;
                    margin-left: 10px;
                    gap: 4px;
                    align-items: center;
                    justify-content: center;
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 0;
                    color: #FFFFFF;
                    .icon{
                        transform: rotateY(180deg);
                    }
                }
                .price{
                    margin-right: 32px;
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    margin-bottom: 4px;
                    color: #1861C6;
                }
                .price-black{
                    margin-right: 32px;
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    margin-bottom: 4px;
                    color: #000000;
                }
            }
            .price{
                margin-right: 32px;
                font-family: ProductSans;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                margin-bottom: 4px;
                color: #1861C6;
            }
            .deliery-item{
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: #7A7D85;
                margin-bottom: 4px;
            }
            .add-card{
                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                border-radius: 8px;
                border: none;
                padding: 12px 24px;
                margin-top: 11px;
            }
            @include r(768) {
                width: 49%;
                padding: 10px 20px 20px;
            }
            @include r(576) {
                width: 76%;
                padding: 0px 20px 20px;
            }
            @include r(479){
                width: 49% !important;
                padding: 0px 20px 20px;
            }
            @include r(420){
                width: 57% !important;
                padding: 0px 20px 20px;
            }
            @include r(387){
                width: 66% !important;
                padding: 0px 20px 20px;
            }
        }
    }
}