.partner-page{
    padding: 32px 0;
    @include r(1024) {
        padding: 30px 0;
        @include r(992) {
            padding: 20px 0 30px;
            }
        }
    .partner-banner{    
        position: relative;
        background: #13369E;
        margin-top: 24px;
        border-radius: 12px;
        overflow: hidden;
        padding: 40px;
        box-sizing: border-box;
        max-height: 600px;
        @include r(992) {
            padding: 0;
            max-height: none;
            .partner-photo-col{
                position: absolute !important;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            
        }
        .for-parten-banner-img{
            position: relative;
          
           
            .partner-bgcolor{
                position: absolute;
                z-index: 1;
                background: #0095FF;
                opacity: 0.75;
                width: 100%;
                height: 100%;
                filter: blur(100px);
                overflow: hidden;
                
            }
            img{
                position: relative;
                z-index: 5;
                max-width: 100%;
                max-height: 100%;
                @include r(992){
            
                    left: 405px;
                    z-index: 5;
                    width: 55%;
                    height: 80%;
                }
                @include r(768){
                    display: none;
                }
            }
        }
        .banner-info{
            position: relative;
            z-index: 7;
            @include r(992) {
                padding: 30px;
                }
                @include r(576){
                    text-align: center;
                }
            .banner-title{
                font-family: ProductSans-Black;
                font-weight: 900;
                font-size: 50px;
                line-height: 73px;
                color: #FFFFFF;
                margin-bottom: 48px;
                @include r(1200) {
                    font-size: 40px;
                    line-height: 53px;
                    margin-bottom: 30px;
                    
                }
                @include r(992) {
                    font-size: 30px ;
                    line-height: 40px;
                    margin-bottom: 20px;
                }
                @include r(768) {
                    font-size: 28px !important;
                    line-height: 40px;
                    margin-bottom: 20px;
                }
                @include r(576) {
                    font-size: 24px !important;
                    line-height: 30px !important;

                }
                @include r(420){
                    font-size: 21px;
                }
            }
            .partner-services{
                margin-bottom: 48px;
                @include r(992) {
                    margin-bottom: 20px;
                }
                .partner-service-item{
                    .photo{
                        margin-bottom: 12px;
                        @include r(576){
                            margin-top: 20px !important;
                        }
                    }
                    .text {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #FFFFFF;
                        @include r(768){
                            font-size: 14px;
                            line-height: 18px;
                        }
                        @include r(479){
                            font-size: 12px !important;
                            line-height: 15px !important; 
                        }
                    }
                }
            }
        }
        
    }

    .partner-steps-block{
        padding: 60px 0;
        @include r(1024) {
            padding: 30px 0;
            @include r(992) {
                padding: 20px 0;
            }
        }
        .step-list{
            margin-top: 60px;
            @include r(1024) {
                margin-top: 30px;
                @include r(992) {
                    margin-top: 20px;
                }
            }
        }
       
        .mt-70{
            margin-top: 70px;
        }
        .step-item{
            text-align: center;
            @include r(992) {
                margin-bottom: 20px;
            }
            .item-photo{
                margin-bottom: 24px;
                position: relative;
                @include r(992) {
                   height: 150px !important;
                    position: relative;
                    margin-bottom: 10px;
                   img{
                       height: 100%;
                   }
                }
                .step-arrow{
                    position: absolute;
                    top: 50%;
                    left: 100% ;
                  transform: translate(-50%, -50%);
                  @include r(768) {
                    display: none;
                }
                }
            }
            .item-text{
                .title{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: center;
                    color: #000000;
                    margin-bottom: 8px;
                    @include r(992) {
                        font-size: 22px;
                        line-height: 25px;
                    }
                }
                .text{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    margin-bottom: 0;
                    color: #000000;
                }
            }

        }
        .for-button{
            margin-top: 60px;
            display: flex;
            justify-content: center;
            @include r(1024) {
                margin-top: 20px;
                margin-bottom: 10px;

                @include r(992) {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .partner-block-title{
       
        font-family: ProductSans;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 42px;
        text-align: center;
        color: #1861C6;
        @include r(1024) {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 0;
            @include r(992) {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 0;

            }
        }

    }
    .offers{
        padding: 60px 0;
        background: #F5F6FA;
        @include r(1024) {
            padding: 30px 0;
            @include r(992) {
                padding: 20px 0;
                
            }
            
        }
        .offers-list{
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
           @include r(576){
            display: block;
           }
            .offer-item{
                width: 31%;
                text-align: center;
                margin-top: 60px;
                @include r(1200) {
                    margin-top: 40px; 
                    }
                    @include r(992) {
                        width: 49%;
                        margin-top: 30px;
                        
                    }
                    @include r(768) {
                        width: 49%;
                        margin-top: 30px;
                    }
                    @include r(576){
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                .item-photo{
                    width: 70px;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    margin-bottom: 24px;
                    @include r(768) {
                        margin-bottom: 16px;
                        
                    }
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .item-text{
                    .title{
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 25px;
                        line-height: 30px;
                        text-align: center;
                        margin-bottom: 8px;
                        color: #000000;
                        @include r(768) {
                            font-size: 20px;
                            line-height: 25px;
                            margin-bottom: 0;
                            
                        }

                    }
                    .text{
                        font-family: ProductSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        margin-bottom: 0;
                        color: #000000;
                    }
                }
            }
        }
    }
    .partner-bottom{
        padding: 60px 0 30px;
        @include r(1200) {
            padding: 40px 0 20px;
            @include r(1024) {
            padding: 20px 0 0px;

            }
            
        }
        .become-partner{
            display: flex;
            height: 100%;
            align-items: center;
            .partner-block-title{
                width: 60%;
                text-align: left;
                margin-bottom: 45px;
                @include r(1200) {
                    width: 100%;
                    margin-bottom: 30px;
                    
                }
                @include r(576){
                    width: 100%;
                    font-size: 21px;
                }
                @include r(420){
                    text-align: center;
                }
            }
            .become-content{
                @include r(420){
                    text-align: center;
                }
            }
        }
        .for-img{
            img{
                max-width: 100%;
                @include r(479){
                    max-width: 90%;
                    margin-left: 25px;
                }
            }
        }
    }
}


.partner-reg-page{
    padding: 32px 0;
    .block-text{
        font-family: ProductSans-Medium;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 32px;
        
    }
    .tap-nav-list{
          width: 100%;  
          .nav-item{
              width: 33.33%;
            .nav-link{
                background-color: #fff;
                padding: 13px 16px;
                font-family: ProductSans-Bold;
                font-size: 16px;
                line-height: 22px;
                color: #A9ADB8;
                border-radius: 0;
                border-bottom: 1px solid #D7DDEB;
                &.active{
                    color: #1861C6;
                    border-color: #1861C6;
                }
                @include r(1200) {
                    padding: 10px;            
                }
            }
          }
    }
    .tab-content{
        margin-top: 40px;
        .tab-pane{
            .for-fomrs{
                .input-item{
                    box-sizing: border-box;
                    margin-bottom: 24px;
                    .label{
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        margin-bottom: 8px;
                    }
                    .input{
                        position: relative;
                        width: 100%;
                        &::after{
                            display: table;
                            content: "";
                            clear: both;
                        }
                        .icon{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 18px;
                            color: #A9ADB8;
                            z-index: 10;
                            left: 16px;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                max-width: 100%;
                            }
                        }
                        .profil-input{
                            padding-left: 42px;
                            height: 56px;
                            box-shadow: none;
                            outline: none;
                            background: #F5F6FA;
                            border: 1px solid #D7DDEB;
                            box-sizing: border-box;
                            border-radius: 8px;
                            &.address{
                                padding: 16px 20px;
                            }
                        }
                        .profil-textarea{
                            box-shadow: none;
                            outline: none;
                            padding: 16px 20px;
                            font-size: 18px;
                            background: #F5F6FA;
                            border: 1px solid #D7DDEB;
                            box-sizing: border-box;
                            border-radius: 8px;
                            resize: none;
                        }

                        .nice-select{
                            background: #F5F6FA;
                            border: 1px solid #D7DDEB;
                            box-sizing: border-box;
                            border-radius: 8px;
                            padding: 0;
                            width: 100%;
                            height: 55px;
                            font-family: ProductSans-Regular;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 23px;
                            color: #000;
                            margin-bottom: 0;
                            &:after{
                                width: 9px;
                                height: 9px;
                            }
                            .current{
                                display: flex;
                                height: 100%;
                                align-items: center;
                                padding-left: 42px;
                                width: 100%;
                                color: #A9ADB8;
                            }
                            .list{
                                width: 100%;
                                z-index: 999;
                                .option{
                                    padding: 3px 20px;
                                    font-size: 14px;
                                    &:hover{
                                        color: #0133EC;
                                        background-color: #fff;
                                    }
                                    &.selected{
                                        background-color: #fff;
                                        color: #A3A3A3;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                   
                }
                .for-button{
                    display: flex;
                    padding-top: 26px;
                    margin-bottom: 30px;
                    justify-content: space-between;
                }
            }
        }
        
    }
    .partner-reg-right{
        padding-top: 50px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .for-img{
            max-width: 100%;
            img{
                width: 100%;
            }
        }
        
    }
}