.profile {
    .profile-content {
        .profile-card {
            background: #F5F6FA;
            border-radius: 8px;
            padding: 16px;
            @include r(768){
                width: 80%;
                margin-left: 65px;
            }
            @include r(576){
                width: 90%;
                margin-left: 35px;
            }
            @include r(479){
                width: 100% !important;
                margin-left: 0px !important;
            }
            .card-list {
                padding: 0;
                margin: 0;
                list-style-type: none;

                .card-link {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 56px;
                    border-radius: 8px;
                    text-decoration: none;
                    padding: 0 16px;

                    &.active {
                        background: #FFFFFF;
                    }

                    .icon {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 16px;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    span {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 22px;
                        color: #000000;
                    }
                }
            }
        }

        .profile-information {
            .user {
                margin-bottom: 32px;
                display: flex;
                align-items: center;
                gap: 24px;

                @include r(1024) {
                    margin-bottom: 25px;

                    @include r(576) {
                        margin-bottom: 16px;

                    }

                }

                .user-photo {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid #FFBF3C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;

                    }
                }

                .user-info {
                    .user-name {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 30px;
                        line-height: 36px;
                        color: #000000;
                        margin-bottom: 8px;

                        @include r(1024) {
                            font-size: 25px;
                            line-height: 30px;

                            @include r(576) {
                                font-size: 20px;
                                line-height: 24px;
                                margin-bottom: 0;

                            }

                        }

                    }

                    .phone {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #7A7D85;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .two-app-button {
            margin-top: 70px;
        }

        .profile-orders {
            .order-item {
                margin-bottom: 30px;

                .order-item-top {
                    background: #F5F6FA;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 4px 4px 0px 0px;
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 18px;

                    @include r(576) {
                        flex-wrap: wrap;
                        gap: 10px;
                    }

                    .item-right,
                    .item-left {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .title {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #7A7D85;
                    }

                    .value {
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                    }

                    .status {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #19CB2B;

                        &.danger {
                            background-color: #FC3F1D;
                        }
                    }
                }

                .order-item-content {
                    display: flex;
                    padding: 12px 11px;                    
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    

                    @include r(768) {
                        flex-wrap: wrap;
                        gap: 20px;

                    }

                    .order-product {
                        width: 100%;
                       
                        @include r(768) {
                            width: 100%;

                            @include r(420) {
                                flex-wrap: wrap;
                                gap: 20px;
                            }
                        }
                        .ordered{
                            display: flex;
                            justify-content: space-between;
                        }
                        .search-product-buy{
           
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;
                            padding: 24px 0 0;
                            padding-right: 20px;
                            @include r(576){
                                display: none;
                            }
                            .discount-price-div{
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                margin-bottom: 4px;
                                @include r(576){
                                    display: none !important;
                                }
                                p{
                                    color: #000000;font-size: 16px;
                                    font-family: ProductSans;
                                    line-height: 19px;opacity: 0.5;
                                }
                                .discoun-price{
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 17px;
                                    margin-bottom: 0;
                                    text-decoration-line: line-through;
                                    color: #7A7D85;
                
                                }
                                .discount{
                                    background: #FC3F1D;
                                    border-radius: 4px;
                                    padding: 4px 8px;
                                    display: flex;
                                    margin-left: 10px;
                                    gap: 4px;
                                    align-items: center;
                                    justify-content: center;
                                    font-family: ProductSans-Medium;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 15px;
                                    margin-bottom: 0;
                                    color: #FFFFFF;
                                    .icon{
                                        transform: rotateY(180deg);
                                    }
                                }
                                .price{
                                    margin-right: 32px;
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 24px;
                                    line-height: 29px;
                                    margin-bottom: 4px;
                                    color: #1861C6;
                                }
                                .price-black{
                                    margin-right: 32px;
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 24px;
                                    line-height: 29px;
                                    margin-bottom: 4px;
                                    color: #000000;
                                }
                            }
                            .price{
                                margin-right: 32px;
                                font-family: ProductSans;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 24px;
                                line-height: 29px;
                                margin-bottom: 4px;
                                color: #1861C6;
                            }
                            .deliery-item{
                                font-family: ProductSans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                color: #7A7D85;
                                margin-bottom: 4px;
                            }
                            .add-card{
                                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                                border-radius: 8px;
                                border: none;
                                padding: 12px 24px;
                                margin-top: 11px;
                            }
                            @include r(768) {
                                width: 35%;
                                padding: 10px 20px 20px;
                            }
                        }
                        .for-product-image {
                            .product-image {
                                width: 140px;
                                height: 140px;
                                display: flex;
                                justify-content: center;

                                img {
                                    padding: 22px 47px 12px;
                                    border: 1px solid #D7DDEB;
                                    max-width: 100%;
                                    max-height: 100%;
                                    @include r(387){                                        
                                        padding: 22px 26px 12px;
                                    }
                                }
                            }
                            .product-left-item{
                                .mini-info-block{
                                    
                                    img{
                                        margin: 0 8px 2px 20px;
                                       @include r(420){
                                        margin: 0;
                                       }
                                    }
                                    h6{
                                        color: #13369E;font-size: 14px;
                                font-family: ProductSans;
                                line-height: 17px;
                                @include r(420){
                                    font-size: 12px;
                                }
                                @include r(387){
                                    width: 125px;
                                }
                                
                                    }
                                    p{
                                        color: #000000;font-size: 14px;
                                        font-family: ProductSans;
                                        line-height: 17px;
                                        @include r(420){
                                            font-size: 13px;
                                        }
                                        @include r(387){
                                            font-size: 11px !important;
                                        }
                                    }
                                }
                            }

                        }

                        .product-information {
                            width: 100%;
                            padding-left: 16px;
                            padding-right: 16px;

                            @include r(420) {
                                width: 100%;
                                padding: 0;
                            }

                            .product-title {
                                width: 45%;
                                margin-top: 20px;
                                font-family: ProductSans-Medium;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;
                                color: #000000;
                                margin-bottom: 8px;
                                @include r(576){
                                    width: 100%;
                                }
                            }

                            .salesman {
                                font-family: ProductSans;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 17px;
                                color: #7A7D85;
                                margin-bottom: 4px;
                            }

                            .shop {
                                font-family: ProductSans;
                                text-decoration: none;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 19px;

                                color: #1861C6;

                            }
                            .product-item-bottom{
                                .tel{
                                    display: flex;
                                    flex-direction:row;
                                    justify-content: space-between;
                                    @include r(576){
                                        display: block;
                                        width: 65%;
                                    }
                                    @include r(479){
                                        display: block;
                                        width: 80% !important;
                                    }
                                    @include r(420){
                                        display: block;
                                        width: 100% !important;
                                    }
                                    .shop-info{
                                        margin-top: 10px;
                                        .shop-top{
                                            display: inline-flex;
                                            gap: 6px;
                                            align-items: center;
                                            margin-bottom: 8px;
                                            span{
                                                font-family: ProductSans;
                                                font-size: 14px;
                                                line-height: 17px;
                                                color: #7A7D85;
                                            }
                                           
                                        }
                                        .stars-content{
                                            margin-left: 14px;
                                            padding-top: 10px;ss
                                            .shop-title{
                                                font-family: ProductSans-Medium;
                                                font-size: 16px;
                                                line-height: 19px;
                                                color: #3D3D3D;
                                                margin-bottom: 8px;
                                                img{
                                                    margin-right: 4px;
                                                }
                                            }
                                        }
                                        .stars-list{
                                            line-height: 16px;
                                          
                                        }
                                    }
                                    .content-contact{
                                        margin-top: 20px;
                                        padding: 0 10px 15px 0;
                                        .contact-text{
                                            padding: 12px 20px;
                                            background: #EFF5FF;
                                            border: 1px solid #1B6CDD;
                                            border-radius: 5px;
                                            display: flex;
                                            text-decoration:none;
                                            color: #454545;font-size: 18px;
                                            line-height: 22px;
                                            font-family: 'ProductSans';
                                            
                                            img{
                                                margin-right: 16px;
                                            }
                                            p{
                                                margin-left: 10px;
                                                color: #454545;font-size: 16px;
                                                line-height: 19px; 
                                                font-family: 'ProductSans';
                                                text-decoration-line: underline;
                                            }
                                        }
                                    }
                                }
                                @include r(768) {
                                    width: 100%;
                                    padding: 0 20px 0;
                                }
                            }
                        }
                    }

                    .order-info {
                        width: 45%;

                        @include r(768) {
                            width: 100%;
                        }

                        .order-info-list {
                            .order-info-item {
                                margin-bottom: 16px;

                                .order-info-title {
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 17px;
                                    color: #7A7D85;
                                    margin-bottom: 4px;
                                }

                                .order-info-text {
                                    font-family: ProductSans;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #000000;
                                    margin-bottom: 0;
                                }
                            }

                            .order-button {
                                padding: 13px 24px;
                                background: linear-gradient(90deg, #FFC857 0%, #FFBF3C 100%);
                                box-shadow: 0px 10px 15px -5px rgba(255, 191, 60, 0.5);
                                border-radius: 8px;
                                font-family: ProductSans-Medium;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;
                                text-align: center;
                                color: #000000;
                            }
                        }

                    }
                }
            }
        }

        .input-list {
            @include r(992){
                width: 100%;
                margin-top: 20px;
            }
            @include r(768){
                margin-top: 20px;
                margin-left: 0 !important;
                width: 100%;
            }
            @include r(576){
                margin-top: 20px;
                margin-left: 22px !important;
                width: 100%;
                padding: 0;
            }
            @include r(479){
                margin-top: 20px !important;
                margin-left: 0px !important;
                width: 96% !important;
                padding: 0 !important;
            }
            .input-item {
                margin-bottom: 24px;

                .gender::after {
                    position: absolute;
                    content: "\276E";
                    color: #000;
                    right: -3px;
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                    bottom: -2px;
                    background: #F5F6FA;
                    border: 1px solid #D7DDEB;
                    border-radius: 0px 0px 8px 8px;
                    padding: 17px 23px;
                }

                .input {
                    position: relative;
                    display: flex;
                    width: 80%;
                    font-family: 'ProductSans';
                    @include r(768){
                        width: 100%;
                    }
                    @include r(576){
                        width: 95%;
                    }
                    .your-gender {
                        padding: 16px 14px 14px 20px;
                        background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        border-radius: 8px 0 0 8px;
                        font-family: 'ProductSans';
                        .gender-check{
                            padding: 16px;
                        }
                    }

                    // &::after{
                    //     display: table;
                    //     content: "";
                    //     clear: both;
                    // }
                    .icon {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 18px;
                        color: #A9ADB8;
                        z-index: 50;
                        left: 16px;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 100%;
                        }
                    }

                    .profil-input {
                        padding-left: 42px;
                        box-shadow: none;
                        outline: none;
                        height: 56px;
                        background: #F5F6FA;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 8px 0 0 8px;
                        font-family: 'ProductSans';
                        &.address {
                            padding: 16px 20px;
                        }
                    }

                    .input-button-label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        padding: 0 25px;
                        height: 56px;
                        background: #F5F6FA;
                        border: 1px solid #D7DDEB;
                        border-left: none;
                        box-sizing: border-box;
                        border-radius: 0px 8px 8px 0px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .two-input {
                width: 44.5%;
                @include r(1024){
                    width: 100%;
                }
            }

            .label {
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 8px;
                color: #000000;

            }
        }
    }
}

.profile-edit {
    .profile-information {
        .input-list {
            .input-item {
                margin-bottom: 24px;

                .input {
                    position: relative;
                    display: flex;

                    &::after {
                        display: table;
                        content: "";
                        clear: both;
                    }

                    .icon {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 18px;
                        color: #A9ADB8;
                        z-index: 50;
                        left: 16px;
                        width: 20px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 100%;
                        }
                    }

                    .profil-input {
                        padding-left: 42px;
                        box-shadow: none;
                        outline: none;
                        height: 56px;
                        background: #F5F6FA;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 8px 0 0 8px;

                        &.address {
                            padding: 16px 20px;
                        }
                    }

                    .input-button-label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 56px;
                        padding: 0 25px;
                        height: 56px;
                        background: #F5F6FA;
                        border: 1px solid #D7DDEB;
                        border-left: none;
                        box-sizing: border-box;
                        border-radius: 0px 8px 8px 0px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .label {
                font-family: ProductSans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 8px;
                color: #000000;

            }
        }

        .profile-user-img {
            width: 100%;
            height: 100%;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
            border-radius: 50%;
        }

        .avatar-upload {
            position: relative;
            width: 100px;
            height: 100px;

            border-radius: 50%;
            border: 2px solid #FFBF3C;
            box-sizing: border-box;
            overflow: hidden;

            .avatar-edit {
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 1;
                bottom: -0px;

                input {
                    display: none;

                    +label {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        height: 28px;
                        margin-bottom: 0;
                        bottom: 0;
                        background: #0000008a;
                        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);

                        transition: all .2s ease-in-out;

                        &:hover {
                            cursor: pointer;
                        }

                    }
                }
            }
        }

        .submit-button {
            margin-top: 20px;
            margin-bottom: 40px;

            @include r(992) {
                margin-top: 0px;
                margin-bottom: 20px;
            }
        }
    }
}

.region-svg-img {
    svg {
        max-width: 100%;
        height: 100%;
        @include r(768){
            max-width: 90%;
        }
        @include r(576){
            padding-right: 35px;
        }
    }
}

.region-item {
    .form-item {

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        [type="radio"]:checked+span,
        [type="radio"]:not(:checked)+span {
            position: relative;
            padding-left: 22px;
            cursor: pointer;
            font-family: ProductSans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            @include r(420){
                font-size: 12px;
            }
        }

        [type="radio"]:checked+span:before,
        [type="radio"]:not(:checked)+span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: #fff;
        }

        [type="radio"]:checked+span:after,
        [type="radio"]:not(:checked)+span:after {
            content: '';
            width: 8px;
            height: 8px;
            display: table;
            background: #FFBF3C;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;

        }

        [type="radio"]:not(:checked)+span:after {
            opacity: 0;
            -webkit-transform: scale(0) translateY(-50%);
            transform: scale(0) translateY(-50%);
        }

        [type="radio"]:checked+span:after {
            opacity: 1;
            -webkit-transform: scale(1) translateY(-50%);
            transform: scale(1) translateY(-50%);

        }
    }

    .form-check-label {
        margin-top: 30px;
        height: 56px;
        width: 100%;
        border: 1px solid #A9ADB8;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 8px;

        span {
            display: flex;
            align-items: center;
        }

        @include r(992) {
            margin-top: 20px;
        }

        color: #7A7D85;

        &:hover {
            cursor: pointer;
        }

        &.active {
            background: linear-gradient(90deg, #1B6EE0 0%, #1861C6 100%);
            box-shadow: 0px 10px 15px -5px rgba(24, 97, 198, 0.5);

            span {

                color: #fff !important;
            }
        }
    }
}