.catalog{
    padding: 30px 0;
    @include r(992) {
        padding: 20px 0;
       
    }
    .slider-block{
        padding:25px 0;
        @include r(992) {
            padding: 10px 0;
            @include r(576) {
              display: none;
            }
        }
        .slick-prev {
            display: none !important;
            
        }
        @include r(768px) {
            .slick-prev {
                display: none !important;
            }
            .slick-next{
                display: none !important;
            }
        }
    }
    .catalog-card{
        padding: 24px 20px;
        background: #F5F6FA;
        border-radius: 8px;
        @include r(1024){
            padding: 24px 10px;
        }
       
        .catalog-list{
            .catalog-item{
                margin-bottom: 24px;
                @include r(992px) {
                    margin-bottom: 16px;
                }
                &:last-child(){
                    margin-bottom: 0;
                }
                &-title{
                    font-family: ProductSans-Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 5px;
                    a{
                        color: #13369E;
                        text-decoration: none;

                    }
                }
                .list{
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    .list-link{
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 19px;
                        color: #000000;
                        text-decoration: none;
                    }
                }
                .colapse-link{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #1861C6;
                    text-decoration: none;
                    display: inline-flex;
                    align-items: center;
                    gap: 7px;
                   .icon{
                       font-size: 13px;
                        line-height: 17px;

                   }

                }
            }
        }
        .form-list{
            margin-bottom: 24px;
            &:last-child(){
                margin-bottom: 0;
            }
            .title{
                font-family: ProductSans-Medium;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 12px;
                color: #13369E;
            }
            &.radio-list{
                .form-item{
                    margin-bottom: 8px;
                    [type="radio"]:checked,
                    [type="radio"]:not(:checked) {
                        position: absolute;
                        left: -9999px;
                    }
                    [type="radio"]:checked + label,
                    [type="radio"]:not(:checked) + label
                    {
                        position: relative;
                        padding-left: 22px;
                        cursor: pointer;
                        display: inline-block;
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: #000000;
                        .star-item{
                            font-size: 16px;
                            color: #D7DDEB;
                            &.active{
                                color: #FFBF3C;
                            }
                        }

                    }
                    [type="radio"]:checked + label:before,
                    [type="radio"]:not(:checked) + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #ddd;
                        border-radius: 100%;
                        background: #fff;
                    }
                    [type="radio"]:checked + label:after,
                    [type="radio"]:not(:checked) + label:after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: #1861C6;
                        position: absolute;
                        top: 4px;
                        left: 4px;
                        border-radius: 100%;
                        -webkit-transition: all 0.2s ease;
                        transition: all 0.2s ease;
                    }
                    [type="radio"]:not(:checked) + label:after {
                        opacity: 0;
                        -webkit-transform: scale(0);
                        transform: scale(0);
                    }
                    [type="radio"]:checked + label:after {
                        opacity: 1;
                        -webkit-transform: scale(1);
                        transform: scale(1);
                    }
                }
            }
            &.checkbox-list{
                .styled-checkbox {
                    position: absolute; // take it out of document flow
                    opacity: 0; // hide it
                  
                    & + label {
                        position: relative;
                        cursor: pointer;
                        padding: 0;
                        font-family: ProductSans;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                    }
                  
                    // Box.
                    & + label:before {
                      content: '';
                      margin-right: 10px;
                      display: inline-block;
                      vertical-align: text-top;
                      width: 17px;
                      height: 16px;
                      background: white;
                      background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 4px;

                    }
                  
                    // Box hover
                    &:hover + label:before {
                        background: #FFFFFF;
                    }
                    
                  
                    // Box checked
                    &:checked + label:before {
                        background: #FFFFFF;
                        border: 1px solid #D7DDEB;
                        box-sizing: border-box;
                        border-radius: 4px;
                    }
                    
                    // Disabled state label.
                    &:disabled + label {
                      color: #b8b8b8;
                      cursor: auto;
                    }
                  
                    // Disabled box.
                    &:disabled + label:before {
                      box-shadow: none;
                      background: #ddd;
                    }
                  
                    // Checkmark. Could be replaced with an image
                    &:checked + label:after {
                      content: '';
                      position: absolute;
                      left: 4px;
                      top: 8px;
                      background: #1861C6;
                      width: 2px;
                      height: 2px;
                      box-shadow: 
                        2px 0 0 #1861C6,
                        4px 0 0 #1861C6,
                        4px -2px 0 #1861C6,
                        4px -4px 0 #1861C6,
                        4px -6px 0 #1861C6,
                        4px -8px 0 #1861C6;
                      transform: rotate(45deg);
                    }
                  }
                  .colapse-link{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    text-decoration: none;
                    color: #1861C6;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-top: 8px;
                  }
                  
            }
        }
        .filter-form{
            .input-list{
                display: flex;
                justify-content: space-between;
                gap: 15px;
                margin-bottom: 12px;
                .price-input{
                    font-family: 'ProductSans';
                    background: #FFFFFF;
                    border: 1px solid #D7DDEB;
                    box-sizing: border-box;
                    border-radius: 8px;
                    height: 48px;
                    resize: none;
                    @include r(1024){
                        font-size: 14px;
                    }
                    &::-webkit-outer-inner-button,&::-webkit-outer-spin-button{
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .form-item{
                label{
                    &.title{
                        font-family: ProductSans-Medium;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 0 !important;
                        color: #13369E;
                    }
                    
                }
            }
        }
        .category-list{
            list-style-type: none;
            padding-left: 18px;
            .category-item{
                .category-link{
                    font-family: ProductSans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    text-decoration: none;
                    
                }
            }
        }
    }
    .advertising-banners{
        .advertising-card{
            box-sizing: border-box;
            border-radius: 4px;
            height: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            margin-bottom: 16px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .brand-slider{
        .slick-prev {
            display: block !important;
        }
        .slick-prev, .slick-next{
            margin:0 10px;    
            top: 50%;
            display: block;
            background: #FFFFFF;
            border: 1px solid #D7DDEB;
            box-sizing: border-box;
            &:hover{
                background: #1861C6;
                border-color: #1861C6;
            }
        }
        .partners-item{
            margin: 0 !important;
        }
    }
}